<template>
    <div>
        <breadcrumbs
            :title="'Države'"
            :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Država: {{ naziv }}</h5>
            </div>
            <div class="ibox-content">
                <drzava-forma
                    :disabled="true"
                    :id="id"
                    :modal="false"
                    :action="'show'"
                    @returnData="setData"
                ></drzava-forma>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import DrzavaForma from "./DrzavaForma";
export default {
    name: "DrzavaDetails",
    components: { DrzavaForma, Breadcrumbs },
    data(){
        return {
            id: this.$route.params.drzava_id,
            breadcrumbs: [],
            naziv: ''
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/postavke/drzave',
                    name: 'Države'
                },
                {
                    path: '/postavke/drzave/' + this.id,
                    name: 'Država: ' + this.naziv
                }
            ]
        },
        setData( data ){
            this.naziv = data.naziv || ''
            this.setBreadcrumbs()
        }
    }
};
</script>

<style scoped>

</style>