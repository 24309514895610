<template>
    <div>
        <breadcrumbs
            :title="'Skupovi'"
            :breadcrumbs="breadcrumbs"
        >
        </breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Uredi skup: {{ naziv }}</h5>
            </div>
            <div class="ibox-content">
                <skup-forma
                    :disabled="false"
                    :id="id"
                    :modal="false"
                    :action="'edit'"
                    @returnData="setData"
                >
                </skup-forma>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import SkupForma from "./SkupForma";
export default {
    name: "SkupEdit",
    components: { SkupForma, Breadcrumbs},
    props: ['user'],
    data(){
        return {
            id: this.$route.params.skup_id,
            breadcrumbs: [],
            naziv: '',
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/skupovi',
                    name: 'Skupovi'
                },
                {
                    path: ( '/skupovi/' + this.id ),
                    name: ( 'Skup: ' + this.naziv )
                },
                {
                    path: ( '/skupovi/' + this.id + '/edit' ),
                    name: ( 'Uredi skup: ' + this.naziv )
                }
            ]
        },
        setData( data ){
            this.naziv = data.naziv
            this.setBreadcrumbs()
        }
    }
};
</script>

<style scoped>

</style>
