import { render, staticRenderFns } from "./SkupPrometBase.vue?vue&type=template&id=20ba034b&scoped=true&"
import script from "./SkupPrometBase.vue?vue&type=script&lang=js&"
export * from "./SkupPrometBase.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20ba034b",
  null
  
)

export default component.exports