import { render, staticRenderFns } from "./OsobaRazdobljeCreate.vue?vue&type=template&id=4453bcee&scoped=true&"
import script from "./OsobaRazdobljeCreate.vue?vue&type=script&lang=js&"
export * from "./OsobaRazdobljeCreate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4453bcee",
  null
  
)

export default component.exports