<template>
    <div>
        <breadcrumbs
            :title="'Osobe'"
            :breadcrumbs="breadcrumbs"
        >
        </breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Uredi osobu: {{ naziv }}</h5>
            </div>
            <div class="ibox-content">
                <osoba-forma
                    :disabled="false"
                    :id="id"
                    :modal="false"
                    :action="'edit'"
                    @returnData="setData"
                >
                </osoba-forma>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import OsobaForma from "./OsobaForma";
export default {
    name: "OsobaEdit",
    components: { OsobaForma, Breadcrumbs},
    props: ['user'],
    data(){
        return {
            id: this.$route.params.osoba_id,
            breadcrumbs: [],
            naziv: '',
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/osobe',
                    name: 'Osobe'
                },
                {
                    path: ( '/osobe/' + this.id ),
                    name: ( 'Osoba: ' + this.naziv )
                },
                {
                    path: ( '/osobe/' + this.id + '/edit' ),
                    name: ( 'Uredi osobu: ' + this.naziv )
                }
            ]
        },
        setData( data ){
            this.naziv = data.ime + ' ' + data.prezime + ' (' + data.oib + ')'
            this.setBreadcrumbs()
        }
    }
};
</script>

<style scoped>

</style>
