<template>
    <div>
        <breadcrumbs
            :title="'Područja teme'"
        ></breadcrumbs>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Novo područje teme</h5>
            </div>
            <div class="ibox-content">
                <podrucje-teme-forma
                    :disabled="false"
                    :id="null"
                    :modal="false"
                    :action="'create'"
                ></podrucje-teme-forma>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import PodrucjeTemeForma from "./PodrucjeTemeForma";
export default {
    name: "PodrucjeTemeCreate",
    components: {
        PodrucjeTemeForma,
        Breadcrumbs
    },
    data(){
        return {
            breadcrumbs: []
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/postavke/podrucja-tema',
                    name: 'Područja tema'
                },
                {
                    path: '/postavke/podrucja-tema/create',
                    name: 'Novo područje teme'
                }
            ]
        }
    },
    mounted() {
        this.setBreadcrumbs()
    }
};
</script>

<style scoped>

</style>
