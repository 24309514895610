<template>
    <div>
        <osobe-navigation
            :id="osoba_id"
            :breadcrumbs="breadcrumbs"
        >
        </osobe-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Grupa statusa: {{ naziv }}</h5>
            </div>
            <div class="ibox-content">
                <osoba-umanjeni-sat-forma
                    :disabled="false"
                    :id="id"
                    :modal="false"
                    :action="'edit'"
                    @returnData="setData"
                >
                </osoba-umanjeni-sat-forma>
            </div>
        </div>
    </div>
</template>

<script>
import OsobeNavigation from "../OsobeNavigation";
import OsobaUmanjeniSatForma from "./OsobaUmanjeniSatForma";
export default {
    name: "OsobaGrupaStatusaEdit",
    components: { OsobaUmanjeniSatForma, OsobeNavigation },
    data(){
        return {
            breadcrumbs: [],
            id:this.$route.params.osoba_umanjeni_sati_id,
            osoba_id: this.$route.params.osoba_id,
            naziv: ''
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/osobe/' + this.osoba_id + '/umanjeni-sati',
                    name: 'Umanjeni sati'
                },
                {
                    path: '/osobe/' + this.osoba_id + '/umanjeni-sati/' + this.id,
                    name: 'Umanjeni sati: ' + this.naziv
                },
                {
                    path: '/osobe/' + this.osoba_id + '/umanjeni-sati/' + this.id + '/edit',
                    name: 'Uredi umanjene sate: ' + this.naziv
                }
            ]
        },
        setData( data ){
            this.naziv = data.umanjeni_sat.naziv
            this.setBreadcrumbs()
        }
    }
};
</script>

<style scoped>

</style>
