<template>
    <div>
        <skupovi-navigation
            :id="skup_id"
            :breadcrumbs="breadcrumbs"
        >
        </skupovi-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Podtema: {{ naziv }}</h5>
            </div>
            <div class="ibox-content">
                <skup-tema2-forma
                    :disabled="true"
                    :id="id"
                    :modal="false"
                    :action="'show'"
                    @returnData="setData"
                >
                </skup-tema2-forma>
            </div>
        </div>
    </div>
</template>

<script>
import SkupoviNavigation from "../SkupNavigation";
import SkupTema2Forma from "./SkupTema2Forma";
export default {
    name: "SkupTema2Details",
    components: { SkupTema2Forma, SkupoviNavigation },
    data(){
        return {
            breadcrumbs: [],
            id:this.$route.params.tema2_id,
            skup_id: this.$route.params.skup_id,
            naziv: ''
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/skupovi/' + this.skup_id + '/teme2',
                    name: 'Podteme'
                },
                {
                    path: '/skupovi/' + this.skup_id + '/teme2/' + this.id,
                    name: 'podteme: ' + this.naziv
                }
            ]
        },
        setData( data ){
            this.naziv = data.naziv
            this.setBreadcrumbs()
        }
    }
};
</script>

<style scoped>

</style>
