<template>
    <div>
        <osobe-navigation
            :id="osoba_id"
            :breadcrumbs="breadcrumbs"
        >
        </osobe-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Novi umanjeni sati</h5>
            </div>
            <div class="ibox-content">
                <osoba-umanjeni-sat-forma
                    :disabled="false"
                    :id="null"
                    :modal="false"
                    :action="'create'"
                >
                </osoba-umanjeni-sat-forma>
            </div>
        </div>
    </div>
</template>

<script>
import OsobeNavigation from "../OsobeNavigation";
import OsobaUmanjeniSatForma from "./OsobaUmanjeniSatForma";
export default {
    name: "OsobaUmanjeniSatCreate",
    components: { OsobaUmanjeniSatForma, OsobeNavigation },
    data(){
        return {
            breadcrumbs: [],
            osoba_id: this.$route.params.osoba_id
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/osobe/' + this.osoba_id + '/umanjeni-sati',
                    name: 'Umanjeni sati'
                },
                {
                    path: '/osobe/' + this.osoba_id + '/umanjeni-sati/create',
                    name: 'Novi umanjeni sati'
                }
            ]
        }
    },
    mounted() {
        this.setBreadcrumbs()
    }
};
</script>

<style scoped>

</style>
