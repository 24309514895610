<template>
    <div>
        <breadcrumbs
            :title="'Umanjeni sati'"
            :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Novi umanjeni sat</h5>
            </div>
            <div class="ibox-content">
                <umanjeni-sat-forma
                    :disabled="false"
                    :id="null"
                    :modal="false"
                    :action="'create'"
                ></umanjeni-sat-forma>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import UmanjeniSatForma from "./UmanjeniSatForma";
export default {
    name: "UmanjeniSatCreate",
    components: {
        UmanjeniSatForma,
        Breadcrumbs
    },
    data(){
        return {
            breadcrumbs: []
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/postavke/umanjeni-sati',
                    name: 'Umanjeni sati'
                },
                {
                    path: '/postavke/umanjeni-sati/create',
                    name: 'Novi umanjeni sat'
                }
            ]
        }
    },
    mounted(){
        this.setBreadcrumbs()
    }
};
</script>

<style scoped>

</style>
