<template>
    <div>
        <breadcrumbs
            :title="'Vrste tema'"
            :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Vrsta teme: {{ naziv }}</h5>
            </div>
            <div class="ibox-content">
                <vrsta-teme-forma
                    :disabled="false"
                    :id="id"
                    :modal="false"
                    :action="'edit'"
                    @returnData="setData"
                ></vrsta-teme-forma>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import VrstaTemeForma from "./VrstaTemeForma";
export default {
    name: "VrstaTemeEdit",
    components: {
        VrstaTemeForma,
        Breadcrumbs,
    },
    data(){
        return {
            id: this.$route.params.vrsta_teme_id,
            breadcrumbs: [],
            naziv: ''
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/postavke/vrste-tema',
                    name: 'Vrste tema'
                },
                {
                    path: '/postavke/vrste-tema/' + this.id,
                    name: 'Vrsta teme: ' + this.naziv
                },
                {
                    path: '/postavke/vrste-tema/' + this.id + '/edit',
                    name: 'Uredi vrstu teme: ' + this.naziv
                }
            ]
        },
        setData( data ){
            this.naziv = data.naziv || ''
            this.setBreadcrumbs()
        }
    }
};
</script>

<style scoped>

</style>
