<template>
    <div>
        <osobe-navigation
            :id="osoba_id"
            :breadcrumbs="breadcrumbs"
        >
        </osobe-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Nova grupa statusa</h5>
            </div>
            <div class="ibox-content">
                <osoba-grupa-statusa-forma
                    :disabled="false"
                    :id="null"
                    :modal="false"
                    :action="'create'"
                >
                </osoba-grupa-statusa-forma>
            </div>
        </div>
    </div>
</template>

<script>
import OsobeNavigation from "../OsobeNavigation";
import OsobaGrupaStatusaForma from "./OsobaGrupaStatusaForma";
export default {
    name: "OsobaGrupaStatusaCreate",
    components: { OsobaGrupaStatusaForma, OsobeNavigation },
    data(){
        return {
            breadcrumbs: [],
            osoba_id: this.$route.params.osoba_id
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/osobe/' + this.osoba_id + '/grupe-statusa',
                    name: 'Grupe statusa'
                },
                {
                    path: '/osobe/' + this.osoba_id + '/grupe-statusa/create',
                    name: 'Nova grupa statusa'
                }
            ]
        }
    },
    mounted() {
        this.setBreadcrumbs()
    }
};
</script>

<style scoped>

</style>
