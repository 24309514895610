<template>
    <div>

        <breadcrumbs
            :title="'Osobe'"
            :breadcrumbs="allBreadcrumbs"
        >
        </breadcrumbs>

        <nav class="navbar navbar-expand-lg navbar-light" style="background-color: #2F4050;">
            <a
                :class="{
                            'navbar-brand btn btn-light btn-xl': true,
                            'active': active_route === route
                        }"
                @click="open(route)"
            >
                <strong>Osoba</strong>
            </a>

            <div v-if="osoba" class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">

                    <li class="nav-item">
                        <a
                            role="button"
                            :class="{
                                    'nav-link btn btn-light': true,
                                    'active': active_route === route + '/grupe-statusa'
                                }"
                            @click.prevent="open( route + '/grupe-statusa' )"
                        >
                            <strong>Grupa</strong>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a
                            role="button"
                            :class="{
                                    'nav-link btn btn-light': true,
                                    'active': active_route === route + '/razdoblja'
                                }"
                            @click.prevent="open( route + '/razdoblja' )"
                        >
                            <strong>Razdoblja</strong>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a
                            role="button"
                            :class="{
                                    'nav-link btn btn-light': true,
                                    'active': active_route === route + '/umanjeni-sati'
                                }"
                            @click.prevent="open( route + '/umanjeni-sati' )"
                        >
                            <strong>Umanjeni sati</strong>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a
                            role="button"
                            :class="{
                                    'nav-link btn btn-light': true,
                                    'active': active_route === route + '/tvrtke'
                                }"
                            @click.prevent="open( route + '/tvrtke' )"
                        >
                            <strong>Tvrtke</strong>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a
                            role="button"
                            :class="{
                                    'nav-link btn btn-light': true,
                                    'active': active_route === route + '/dodatne-aktivnosti'
                                }"
                            @click.prevent="open( route + '/dodatne-aktivnosti' )"
                        >
                            <strong>Dodatne aktivnosti</strong>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a
                            role="button"
                            :class="{
                                    'nav-link btn btn-light': true,
                                    'active': active_route === route + '/stavke'
                                }"
                            @click.prevent="open( route + '/stavke' )"
                        >
                            <strong>Stavke</strong>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a
                            role="button"
                            :class="{
                                    'nav-link btn btn-light': true,
                                    'active': active_route === route + '/potvrde'
                                }"
                            @click.prevent="open( route + '/potvrde' )"
                        >
                            <strong>Potvrde</strong>
                        </a>
                    </li>

                </ul>
            </div>
        </nav>


    </div>
</template>

<script>

import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import axios from "axios";
export default {
    name: "OsobeNavigation",
    components: {Breadcrumbs},
    props:[
        'id',
        'breadcrumbs'
    ],
    data(){
        return {
            naziv: '',
            route: '/osobe/' + this.id,
            active_route: '',
            allBreadcrumbs: [],
            osoba: null
        }
    },
    methods:{
        open( name ){
            if( name !== this.active_route ){
                this.active_route = name
                this.$router.push( name )
            }
        },
        getOsoba(){
            let vm = this
            axios.get( process.env.VUE_APP_API_URL + 'osoba' + '/' + vm.id )
                .then( function ( response ) {
                    vm.osoba = response.data
                    vm.setBreadcrumbs()
                } )
                .catch( function ( error ) {
                    if(error instanceof Error) {
                        console.log( 'Error: ' . error);
                    } else {
                        console.log( 'Unexpected response: ' . error);
                    }
                } )
        },
        setBreadcrumbs(){
            let vm = this

            if( vm.osoba ){
                vm.allBreadcrumbs = [
                    {
                        path: '/osobe',
                        name: 'Osobe'
                    },
                    {
                        path: ( '/osobe/' + this.id ),
                        name: ( 'Osoba: ' + this.osoba.ime + ' ' + this.osoba.prezime + ' (' + this.osoba.oib + ')' )
                    },
                ]

                vm.breadcrumbs.forEach( function( b ){
                    vm.allBreadcrumbs.push( b )
                } )
            }
        }
    },
    mounted() {
        this.active_route = this.$route.path
        this.getOsoba()
    },
    watch:{
        breadcrumbs: {
            deep: true,
            handler: function(){
                this.setBreadcrumbs()
            }
        },
    },
};
</script>

<style scoped>

</style>
