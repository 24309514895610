var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('breadcrumbs',{attrs:{"title":'Skupovi',"breadcrumbs":_vm.allBreadcrumbs}}),_c('nav',{staticClass:"navbar navbar-expand-lg navbar-light",staticStyle:{"background-color":"#2F4050"}},[_c('a',{class:{
                        'navbar-brand btn btn-light btn-xl': true,
                        'active': _vm.active_route === _vm.route
                    },on:{"click":function($event){return _vm.open(_vm.route)}}},[_c('strong',[_vm._v("Skup")])]),(_vm.skup)?_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav mr-auto"},[_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                'nav-link btn btn-light': true,
                                'active': _vm.active_route === _vm.route + '/teme'
                            },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/teme' )}}},[_c('strong',[_vm._v("Teme")])])]),_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                'nav-link btn btn-light': true,
                                'active': _vm.active_route === _vm.route + '/teme2'
                            },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/teme2' )}}},[_c('strong',[_vm._v("Podteme")])])]),_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                'nav-link btn btn-light': true,
                                'active': _vm.active_route === _vm.route + '/suorganizatori'
                            },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/suorganizatori' )}}},[_c('strong',[_vm._v("Suorganizatori")])])]),_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                'nav-link btn btn-light': true,
                                'active': _vm.active_route === _vm.route + '/prijava'
                            },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/prijave' )}}},[_c('strong',[_vm._v("Prijave")])])]),_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                'nav-link btn btn-light': true,
                                'active': _vm.active_route === _vm.route + '/stavke'
                            },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/stavke' )}}},[_c('strong',[_vm._v("Stavke")])])]),_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                'nav-link btn btn-light': true,
                                'active': _vm.active_route === _vm.route + '/promet'
                            },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/promet' )}}},[_c('strong',[_vm._v("Promet")])])]),_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                'nav-link btn btn-light': true,
                                'active': _vm.active_route === _vm.route + '/emailing'
                            },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/emailing' )}}},[_c('strong',[_vm._v("Emailing")])])])])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }