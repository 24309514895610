<template>
    <div>
        <forms
            :settings="settings"
            @onFormButtonClick="onFormButtonClick"
            @onInput="onInput"
        >
        </forms>
    </div>
</template>

<script>
import Forms from "../../../../components/form/Forms";
import axios from "axios";
import toastr from "toastr";
import moment from "moment";
export default {
    name: "SkupStavkaForma",
    components: { Forms },
    props:{
        disabled:{
            type: Boolean,
            default: false
        },
        id:{
            default: null
        },
        modal:{
            type: Boolean,
            default: false
        },
        action: {
            type: String
        }
    },
    data(){
        return {
            settings: {},
            errors: {},
            route: '/skupovi/' + this.$route.params.skup_id + '/stavke',
            url: 'stavka',
            data: {},
            skup_id: this.$route.params.skup_id,


            vrste_sudionika_select: [],
            osobe_select: [],
            tema_select: [],
            tema2_select: [],
            bodovanje_select: [
                {
                    key: 1,
                    value: 'Skup'
                },
                {
                    key: 2,
                    value: 'Tema'
                },
                {
                    key: 3,
                    value: 'Podtema'
                }
            ]
        }
    },

    methods: {



        async fetctVrsteSudionika(){
            let vm = this
            const response = await axios.get(process.env.VUE_APP_API_URL + 'vrsta-sudionika' )
            return response.data.data.map( function( item ) {
                vm.vrste_sudionika_select.push(
                    {
                        key: item.id.toString(),
                        value: item.naziv
                    }
                )
            } )
        },

        getVrstaSudionika(id) {
            return this.vrste_sudionika_select.find( item => item.key === id )
        },

        async fetchOsobe(){
            let vm = this
            const response = await axios.get(process.env.VUE_APP_API_URL + 'osoba2' )
            return response.data.data.map( function( item ) {
                vm.osobe_select.push(
                    {
                        key: item.id.toString(),
                        value: item.ime + ' ' + item.prezime + ' (' + item.oib + ')'
                    }
                )
            } )
        },

        getOsoba( id ){
            return this.osobe_select.find( item => item.key === id )
        },

        async fetchTama( skup_id ){
            let vm = this
            const response = await axios.get(process.env.VUE_APP_API_URL + 'tema', {
                params: {
                    filters: {
                        skup_id
                    }
                }
            } )
            return response.data.data.map( function( item ) {
                vm.tema_select.push(
                    {
                        key: item.id.toString(),
                        value: item.broj_teme + ' ' + item.naziv
                    }
                )
            } )
        },

        getTema( id ){
            return this.tema_select.find( item => item.key === id )
        },

        async fetchTema2( tema_id ){
            let vm = this
            const response = await axios.get(process.env.VUE_APP_API_URL + 'tema2', {
                params: {
                    filters: {
                        tema_id
                    }
                }
            } )
            return response.data.data.map( function( item ) {
                vm.tema2_select.push(
                    {
                        key: item.id.toString(),
                        value: item.broj_teme2 + ' ' + item.naziv
                    }
                )
            } )
        },

        getTema2( id ){
            return this.tema2_select.find( item => item.key === id )
        },



        async init(){

            let vm = this

            vm.open_loader = true

            await vm.fetctVrsteSudionika()
            await vm.fetchOsobe()
            await vm.fetchTama( vm.skup_id )

            if ( vm.action === 'edit' || vm.action === 'show' ){
                axios.get( process.env.VUE_APP_API_URL + vm.url + '/' + vm.id )
                    .then( async function ( response ) {
                        vm.data['osoba_id'] = response.data.osoba_id.toString()
                        vm.data['skup_id'] = response.data.skup_id.toString()
                        vm.data['tema_id'] = response.data.tema_id ? response.data.tema_id.toString() : ''
                        vm.data['tema2_id'] = response.data.tema2_id ? response.data.tema2_id.toString() : ''
                        vm.data['vrsta_sudionika_id'] = response.data.vrsta_sudionika_id.toString()
                        vm.data['datum_prijave'] = response.data.datum_prijave

                        if( vm.data['tema2_id'] )
                            await vm.fetchTema2( vm.data['tema_id'] )

                        vm.$emit( 'returnData', response.data )
                        vm.setupForm()
                        vm.open_loader = false
                    } )
                    .catch( function ( error ) {
                        vm.open_loader = false
                        if(error instanceof Error) {
                            console.log( 'Error: ' . error);
                        } else {
                            console.log( 'Unexpected response: ' . error);
                        }
                    } )
            } else if ( vm.action === 'create' ) {
                vm.data = {}
                vm.setupForm()
                vm.open_loader = false
            } else {
                vm.open_loader = false
            }
        },

        setupForm(){

            let vm = this;

            vm.buttons = []

            let button_cancel = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
                action: 'redirect', // request
                config: {
                    url: vm.route + ( vm.action === 'edit' ? ( '/' + vm.id) : '' )
                }
            }

            let button_delete = {
                id: 'delete',
                title: 'Obriši',
                type: 'danger',
                action: 'request',
                config: {
                    method: 'DELETE',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let button_edit = {
                id: 'edit',
                title: 'Uredi',
                type: 'success',
                action: 'redirect',
                config: {
                    url: vm.route +  '/' + vm.id +'/edit'
                }
            }

            let button_save = {
                id: 'save',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + vm.url
                }
            }

            let button_update = {
                id: 'update',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'PUT',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let buttons = []


            if( vm.action === 'edit' ){
                buttons.push( button_cancel)
                buttons.push( button_update)
            } else if( vm.action === 'show' ) {
                buttons.push( button_cancel )
                buttons.push( button_delete )
                buttons.push( button_edit )
            }
            else if ( vm.action === 'create' ) {
                buttons.push( button_cancel )
                buttons.push( button_save )
            } else {
                return
            }

            let inputs = []


            let skup_id_input = {
                name: 'skup_id',
                type: 'hidden',
                value: vm.skup_id,
                disabled: vm.disabled,
                config: {
                    hidden:true
                }
            }
            inputs.push( skup_id_input )

            let osoba_id_input = {
                name: 'osoba_id',
                type: 'select',
                title: 'Osoba',
                value: vm.data.osoba_id ? vm.getOsoba( vm.data.osoba_id ) : '',
                width: 12,
                error: vm.errors['osoba_id'] ? vm.errors['osoba_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.osobe_select,
                }
            }
            inputs.push( osoba_id_input )

            let tema_id_input = {
                name: 'tema_id',
                type: 'select',
                title: 'Tema',
                value: vm.data.tema_id ? vm.getTema( vm.data.tema_id ) : '',
                width: 12,
                error: vm.errors['tema_id'] ? vm.errors['tema_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.tema_select,
                }
            }
            inputs.push( tema_id_input )

            let tema2_id_input = {
                name: 'tema2_id',
                type: 'select',
                title: 'Podteme',
                value: vm.data.tema2_id ? vm.getTema2( vm.data.tema2_id ) : '',
                width: 12,
                error: vm.errors['tema2_id'] ? vm.errors['tema2_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.tema2_select,
                }
            }
            inputs.push( tema2_id_input )

            let vrste_sudionika_id_input = {
                name: 'vrsta_sudionika_id',
                type: 'select',
                title: 'Vrsta sudionika',
                value: vm.getVrstaSudionika( vm.data.vrsta_sudionika_id ),
                width: 12,
                error: vm.errors['vrsta_sudionika_id'] ? vm.errors['vrsta_sudionika_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.vrste_sudionika_select,
                    multiple: false
                }
            }
            inputs.push( vrste_sudionika_id_input )

            let datum_input = {
                name: 'datum',
                type: 'datepicker',
                title: 'Datum',
                value:  vm.data.datum || moment( new Date(), 'DD.MM.YYYY.').format('DD.MM.YYYY.'),
                width: 12,
                error: vm.errors['datum'] ? vm.errors['datum']: '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }
            inputs.push( datum_input )


            let main_section = {
                name: ' ',
                width: 12,
                inputs: inputs
            }

            let sections = []

            sections.push( main_section )



            let settings = {
                width: 6,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: sections
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },

        /**
         *  on input return data
         */
        async onInput( name, value ){

            if( name === 'tema_id' && this.data[name] !== value ){
                this.data[name] = value
                this.data['tema2_id'] = null
                await this.fetchTema2( value )
            } else {
                if( this.data[name] !== value ){
                    this.data[name] = value
                }
            }
        },

        /**
         *  on button click
         */
        onFormButtonClick( id, action, config, data  ){

            if( id === 'save' || id === 'update' ){
                if( data.success){
                    if( id == 'save' )
                        toastr.success( 'Uspješno spremljeno!' )
                    else if ( id == 'update' )
                        toastr.success( 'Uspješno ažurirano!' )
                    this.$router.push({ path: this.route + '/' + data.data.id } )
                } else {
                    this.errors = data.messages
                    this.setupForm()
                }
            } else if( id == 'delete' ){
                toastr.error( 'Uspješno obrisano!' )
                this.$router.push({ path: this.route } )
            }

        },
    },

    mounted() {
        this.init()
    }
}
</script>

<style scoped>

</style>
