<template>
    <div>

        <div class="vld-parent">
            <loading :active.sync="open_loader"
                     :can-cancel="false"
                     :is-full-page="true">
            </loading>
        </div>

        <osobe-navigation
            :id="osoba_id"
            :breadcrumbs="breadcrumbs"
        >
        </osobe-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Promet</h5><br>
            </div>
            <div class="ibox-content">
                <forms
                    :settings="settings"
                    @onFormButtonClick="onFormButtonClick"
                    @onInput="onInput"
                ></forms>
            </div>
        </div>

        <div class="ibox">
            <div class="ibox-title">
                <h5>Izdane potvrde</h5><br>
            </div>
            <div class="ibox-content">
                <datatables
                    v-if="show_datatable"
                    :url="datatables_url"
                    :settings="datatables.settings"
                    :title="datatables.title"
                    @buttonClick="datatableButtonClick"
                >
                </datatables>
            </div>
        </div>
    </div>
</template>

<script>
import Datatables from "../../../../components/datatable/Datatables";
import Forms from "../../../../components/form/Forms";
import axios from "axios";
import Loading from "vue-loading-overlay";
import toastr from "toastr";
import 'vue-loading-overlay/dist/vue-loading.css';
import OsobeNavigation from "../OsobeNavigation";
export default {
    name: "OsobaPotvrda",
    components: { OsobeNavigation, Datatables, Forms, Loading },
    data(){
        return {
            osoba_id: this.$route.params.osoba_id,

            open_loader: false,

            // datatable
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL +  'generirani-dokument',
            datatables: {},
            breadcrumbs: [],

            // form
            settings: {},
            errors: {},
            data: {},

            //potvrde
            potvrde: [],
            // select options
            potvrde_select: [],
            stavke_select: [],
            razdoblja_select: []
        }
    },
    methods:{

        async init(){
            this.open_loader = true
            await this.fetchPotvrde()
            await this.fetchStavke()
            await this.fetchRazdoblja()
            this.setupForm()
            this.setDatatable()
            this.setBreadcrumbs()
            this.open_loader = false
        },

        async fetchPotvrde(){
            let vm = this
            await axios.get( process.env.VUE_APP_API_URL + 'potvrda', {
                params:{
                    page: 1,
                    per_page: -1,
                }
            } )
                .then( response => {
                    vm.potvrde = response.data.data
                    response.data.data.forEach( item => {
                        vm.potvrde_select.push( {
                            key: item.id.toString(),
                            value: item.naziv
                        } )
                    })
                } )
                .catch( error => console.log( error ) )
        },

        async fetchStavke(){
            let vm = this
            await axios.get( process.env.VUE_APP_API_URL + 'stavka', {
                params:{
                    page: 1,
                    per_page: -1,
                    filters: {
                        osoba_id: vm.osoba_id,
                        vrsta_sudionika_id: 1
                    }
                }
            } )
                .then( response => {
                    response.data.data.forEach( item => {
                        let exists = vm.getStavke( item.skup.id.toString() )
                        if( !(Boolean(exists))){
                            vm.stavke_select.push( {

                                key: item.skup.id.toString(),
                                value: item.skup.naziv
                            } )
                        }
                    })
                } )
                .catch( error => console.log( error ) )
        },

        async fetchRazdoblja(){
            let vm = this
            await axios.get( process.env.VUE_APP_API_URL + 'osoba-razdoblje', {
                params:{
                    page: 1,
                    per_page: -1,
                    filters: {
                        osoba_id: vm.osoba_id
                    }
                }
            } )
                .then( response => {
                    response.data.data.forEach( item => {

                        vm.razdoblja_select.push( {
                            key: item.id.toString(),
                            value: item.datum_od + ' ' + item.datum_do
                        } )

                    })
                } )
                .catch( error => console.log( error ) )
        },

        getPotvrda( id ){
            return this.potvrde_select.find( item => item.key === id )
        },

        getPotvrdaData( id ){
            return this.potvrde.find( item => item.id.toString() === id )
        },

        getStavke( id ){
            return this.stavke_select.find( item => item.key === id )
        },

        getRazdoblje(id) {
            return this.razdoblja_select.find( item => item.key === id )
        },


        setupForm(){

            let vm = this

            let cancel_button = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
            }

            let generate_button = {
                id: 'add',
                title: 'Generiraj',
                type: 'primary',
            }

            let buttons = []
            buttons.push( cancel_button )
            buttons.push( generate_button )

            let potvrda_id_select = {
                name: 'potvrda_id',
                type: 'select',
                title: 'Potvrda',
                value: vm.getPotvrda( vm.data.potvrda_id ),
                width: 12,
                error: vm.errors['potvrda_id'] ? vm.errors['potvrda_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.potvrde_select,
                    title_width: 4,
                    input_width: 8
                }
            }

            let stavka_id_input = {
                name: 'skup_id',
                type: 'select',
                title: 'Skup',
                value: vm.getStavke( vm.data.skup_id ),
                width: 12,
                error: vm.errors['skup_id'] ? vm.errors['skup_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.stavke_select,
                    title_width: 4,
                    input_width: 8
                }
            }

            let razdoblje_id_input = {
                name: 'razdoblje_id',
                type: 'select',
                title: 'Razdoblje',
                value: vm.getStavke( vm.data.razdoblje_id ),
                width: 12,
                error: vm.errors['razdoblje_id'] ? vm.errors['razdoblje_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.razdoblja_select,
                    title_width: 4,
                    input_width: 8
                }
            }





            let inputs = []
            inputs.push( potvrda_id_select )
            if( vm.data.potvrda_id ){
                let potvrda = vm.getPotvrdaData( vm.data.potvrda_id )
                if( potvrda.predlozak === 'sudionik' ){
                    inputs.push( stavka_id_input )
                } else if( potvrda.predlozak === 'kartica' ){
                    inputs.push( razdoblje_id_input )
                }
            }



            let settings = {
                width: 6,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: inputs,
                            },
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },


        onInput( name, value ){
            let vm = this;
            if( vm.data[name] !== value ){
                vm.data[name] = value
                vm.setupForm()
            }
        },


        onFormButtonClick( id, action, config, data ){

            console.log( data )


            let vm = this
            if( id === 'add' ){

                if( data.potvrda_id ){
                    let potvrda = vm.getPotvrdaData( data.potvrda_id )

                    if( potvrda.predlozak === 'sudionik' ){

                        if( data.skup_id ){

                            vm.open_loader = true
                            axios.get( process.env.VUE_APP_API_URL + 'generirani-dokument/generate-sudionik-pdf/'
                                + data.potvrda_id + '/'
                                + vm.osoba_id +'/'
                                + data.skup_id
                            )
                                .then( response => {
                                    console.log( response.data )
                                    if( response.data.success ){
                                        toastr.success( 'Uspješno kreirano!' )
                                        vm.data = {}
                                        vm.setupForm()
                                        vm.setDatatable()
                                        vm.open_loader = false
                                    } else {
                                        toastr.error( 'Došlo je do greške!' )
                                        vm.open_loader = false
                                    }
                                } )
                                .catch( error => {
                                    console.log( error )
                                    vm.open_loader = false
                                    toastr.error( 'Došlo je do greške!' )
                                } )

                        } else {
                            toastr.error( 'Odaberite skup!' );
                        }
                    } else if ( potvrda.predlozak === 'kartica' ){

                        if( data.razdoblje_id ){

                            vm.open_loader = true
                            axios.get( process.env.VUE_APP_API_URL + 'generirani-dokument/generate-kartica-pdf/'
                                + data.potvrda_id + '/'
                                + vm.osoba_id +'/'
                                + data.razdoblje_id
                            )
                                .then( response => {
                                    console.log( response.data )
                                    if( response.data.success ){
                                        toastr.success( 'Uspješno kreirano!' )
                                        vm.data = {}
                                        vm.setupForm()
                                        vm.setDatatable()
                                        vm.open_loader = false
                                    } else {
                                        toastr.error( 'Došlo je do greške!' )
                                        vm.open_loader = false
                                    }
                                } )
                                .catch( error => {
                                    console.log( error )
                                    vm.open_loader = false
                                    toastr.error( 'Došlo je do greške!' )
                                } )

                        } else {
                            toastr.error( 'Odaberite razdoblje!' );
                        }
                    }
                } else {
                    toastr.error( 'Odaberite potvrdu!')
                }
            } else if ( id === 'cancel' ){
                vm.data = {}
                vm.setupForm()
            }
        },



        setDatatable() {
            let vm = this;
            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'osoba_id',
                            'visible': false,
                            'filter':{
                                'type': 'none',
                                'value': vm.osoba_id
                            },
                        },
                        {
                            'id': 'potvrda_naziv',
                            'title': 'Potvrda',
                            'type' : function( row ){
                                return row.potvrda ? row.potvrda.naziv : ''
                            },
                            'column_visibility_group': {
                                'id': 'potvrde',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'skup_naziv',
                            'title': 'Skup',
                            'type' : function( row ){
                                return row.skup ? row.skup.naziv : ''
                            },
                            'column_visibility_group': {
                                'id': 'potvrde',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'datum_i_vrijeme',
                            'title': 'Datum',
                            'type' : 'text',
                            'searchable': false,
                            'column_visibility_group': {
                                'id': 'potvrde',
                                'visible' : true
                            },
                            'filter': {
                                'type': 'date',
                            }
                        },
                        {
                            'id': 'download',
                            'title': '',
                            'searchable': false,
                            'type': 'button',
                            'filter': {
                                'type': 'none',
                                'search': '',
                                'value': null,
                            },
                            'column_visibility_group': {
                                id: 'potvrde',
                            },
                            config: {
                                title: 'Preuzmi PDF',
                                type: 'success',
                            }
                        },

                    ],
                    column_visibility_groups:{
                        'potvrde' : 'Potvrde'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Potvrde',
            }
            vm.show_datatable = true;
        },


        async datatableButtonClick( col, row ){
             if( col.id === 'download' ){
                await this.downloadMailPDF( row.id )
            }
        },


        async downloadMailPDF( id ){
            let vm = this;
            vm.open_loader = true;
            await axios.get( process.env.VUE_APP_API_URL + 'generirani-dokument/download-mail-pdf/' + id, {
                responseType: 'blob',
            } )
                .then( response => {
                    const url = window.URL.createObjectURL(new Blob([response.data] ) )
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', id + '.pdf'  )
                    document.body.appendChild( link )
                    link.click()
                    vm.open_loader = false
                })
                .catch( error => {
                    console.log( error )
                    vm.open_loader = false;
                })
        },


        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/osobe/' + this.osoba_id + '/potvrde',
                    name: 'Potvrde'
                }
            ]
        }
    },
    mounted() {
        this.init()
    }
}
</script>

<style scoped>

</style>
