<template>
    <div>
        <breadcrumbs
            :title="'Osobe'"
            :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="inbox">
            <div class="ibox-title">
                <h5>Nova osoba</h5><br>
            </div>
            <div class="ibox-content">
                <osoba-forma
                    :disabled="false"
                    :id="null"
                    :modal="false"
                    :action="'create'"
                ></osoba-forma>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import OsobaForma from "./OsobaForma";
export default {
    name: "OsobaCreate",
    components: { OsobaForma, Breadcrumbs },
    props:['user'],
    data(){
        return {
            breadcrumbs: []
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/osobe',
                    name: 'Osobe'
                },
                {
                    path: '/osobe/create',
                    nome: 'Nova osoba'
                },
            ]
        }
    },
    mounted() {
        this.setBreadcrumbs()
    }
};
</script>

<style scoped>

</style>
