<template>
    <div>
        <skupovi-navigation
            :id="skup_id"
            :breadcrumbs="breadcrumbs"
        >
        </skupovi-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Teme</h5><br>
                <button
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click.prevent="create"
                >
                    Novi unos
                </button>
            </div>
            <div class="ibox-content">
                <datatables
                    v-if="show_datatable"
                    :url="datatables_url"
                    :settings="datatables.settings"
                    :title="datatables.title"
                    @rowClick="rowClick"
                >
                </datatables>
            </div>
        </div>
    </div>
</template>

<script>
import Datatables from "../../../../components/datatable/Datatables";
import SkupoviNavigation from "../SkupNavigation";
import axios from "axios";
export default {
    name: "SkupTeme",
    components: { SkupoviNavigation, Datatables },
    data(){
        return {
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL +  'tema',
            datatables: {},
            breadcrumbs: [],
            skup_id: this.$route.params.skup_id
        }
    },
    methods:{
        rowClick( row ){
            this.$router.push( { path: '/skupovi/' + this.skup_id + '/teme/' + row.id } )
        },

        create(){
            this.$router.push( { path: '/skupovi/' + this.skup_id + '/teme/create' } )
        },

        setDatatable() {
            let vm = this;
            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'skup_id',
                            'visible': false,
                            'filter':{
                                'type': 'none',
                                'value': vm.skup_id
                            },
                        },
                        {
                            'id': 'broj_teme',
                            'title': 'Broj teme',
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'teme',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'naziv',
                            'title': 'Naziv',
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'teme',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'datum_i_vrijeme_od',
                            'title': 'Početak',
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'teme',
                                'visible' : true
                            },
                            'filter': {
                                'type': 'date',
                            }
                        },
                        {
                            'id': 'datum_i_vrijeme_do',
                            'title': 'Završetak',
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'teme',
                                'visible' : true
                            },
                            'filter': {
                                'type': 'date',
                            }
                        },
                        {
                            'id': 'predavaci',
                            'title': 'Predavači',
                            'searchable': false,
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'predavači',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'vrsta_teme_id',
                            'title': 'Vrsta teme',
                            'type' : function( row ){
                                return row.vrsta_teme ? row.vrsta_teme.naziv : ''
                            },
                            'filter': {
                                'type': 'select',
                                'search': function ( ){
                                    let component = this;

                                    axios.get( process.env.VUE_APP_API_URL +  'vrsta-teme', {
                                        params: {
                                            filters: {}
                                        }
                                    } )
                                        .then( function ( response ) {
                                            let tmp = [];

                                            response.data.data.forEach( function ( item ) {
                                                tmp.push({
                                                    key: item.id,
                                                    value: item.naziv
                                                } );
                                            } );
                                            component.$data.tmp = tmp;
                                        } )
                                        .catch( function ( error ) {
                                            if(error instanceof Error) {
                                                console.log( 'Error: ' . error);
                                            } else {
                                                console.log( 'Unexpected response: ' . error);
                                            }
                                        } )
                                },
                            },
                            'column_visibility_group': {
                                'id': 'tema',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'podrucje_teme_id',
                            'title': 'Područje teme',
                            'type' : function( row ){
                                return row.podrucje_teme ? row.podrucje_teme.naziv : ''
                            },
                            'filter': {
                                'type': 'select',
                                'search': function ( ){
                                    let component = this;

                                    axios.get( process.env.VUE_APP_API_URL +  'podrucje-teme', {
                                        params: {
                                            filters: {}
                                        }
                                    } )
                                        .then( function ( response ) {
                                            let tmp = [];

                                            response.data.data.forEach( function ( item ) {
                                                tmp.push({
                                                    key: item.id,
                                                    value: item.naziv
                                                } );
                                            } );
                                            component.$data.tmp = tmp;
                                        } )
                                        .catch( function ( error ) {
                                            if(error instanceof Error) {
                                                console.log( 'Error: ' . error);
                                            } else {
                                                console.log( 'Unexpected response: ' . error);
                                            }
                                        } )
                                },
                            },
                            'column_visibility_group': {
                                'id': 'tema',
                                'visible' : true
                            }
                        },


                    ],
                    column_visibility_groups:{
                        'teme' : 'Teme'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Teme',
            }
            vm.show_datatable = true;
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/skupovi/' + this.skup_id + '/teme',
                    name: 'Teme'
                }
            ]
        }
    },
    mounted() {
        this.setDatatable()
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>
