<template>
    <div>
        <breadcrumbs
            :title="'Područja tema'"
            :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Područje teme: {{ naziv }}</h5>
            </div>
            <div class="ibox-content">
                <podrucje-teme-forma
                    :disabled="true"
                    :id="id"
                    :modal="false"
                    :action="'show'"
                    @returnData="setData"
                ></podrucje-teme-forma>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import PodrucjeTemeForma from "./PodrucjeTemeForma";
export default {
    name: "PodrucjeTemeDetails",
    components: {
        PodrucjeTemeForma,
        Breadcrumbs
    },
    data(){
        return {
            id: this.$route.params.podrucje_teme_id,
            breadcrumbs: [],
            naziv: ''
        }
    },
    methods:{
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/postavke/podrucja-tema',
                    name: 'Područja tema'
                },
                {
                    path: '/postavke/podrucja-tema/' + this.id,
                    name: 'Područje teme: ' + this.naziv
                }
            ]
        },
        setData( data ){
            this.naziv = data.naziv || ''
            this.setBreadcrumbs()
        }
    }
};
</script>

<style scoped>

</style>
