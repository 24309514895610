<template>
    <div>
        <breadcrumbs
            :title="'Umanjeni sati'"
            :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Umanjeni sati</h5>
                <br>
                <button
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click.prevent="create"
                >
                    Novi umanjeni sat
                </button>
            </div>

        </div>
        <div class="ibox-content">
            <datatables
                v-if="show_datatable"
                :url="datatables_url"
                :settings="datatables.settings"
                :title="datatables.title"
                @rowClick="rowClick"
            ></datatables>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import Datatables from "../../../../components/datatable/Datatables";
export default {
    name: "PodrucjaTema",
    components: { Datatables, Breadcrumbs },
    data(){
        return {
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL + 'umanjeni-sat',
            datatables: {},
            breadcrumbs: []
        }
    },
    methods: {
        rowClick( row ){
            this.$router.push( { path: '/postavke/umanjeni-sati/' + row.id } )
        },

        create(){
            this.$router.push( { path: '/postavke/umanjeni-sati/create' } )
        },

        setDatatable(){
            let vm = this

            vm.datatables = {
                settings: {
                    table_header: [
                        {
                            'id': 'id',
                            'visible': false
                        },
                        {
                            'id': 'naziv',
                            'title': 'Naziv',
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'umanjeni-sat',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'sati',
                            'title': 'Sati',
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'umanjeni-sat',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'sati_regulativa',
                            'title': 'Sati regulativa',
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'umanjeni-sat',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'broj_mjeseci',
                            'title': 'Broj mjeseci',
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'umanjeni-sat',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'opis',
                            'title': 'Opis',
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'umanjeni-sat',
                                'visible' : true
                            }
                        },
                    ],
                    column_visibility_groups:{
                        'umanjeni-sat' : 'Umanjeni sati'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Umanjeni sati'
            }

            vm.show_datatable = true;
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/postavke/umanjeni-sat',
                    name: 'Umanjeni sati'
                }
            ]
        }
    },
    mounted() {
        this.setDatatable()
        this.setBreadcrumbs()
    }

};
</script>

<style scoped>

</style>
