import { render, staticRenderFns } from "./VrstaPrometaForma.vue?vue&type=template&id=16ea2f09&scoped=true&"
import script from "./VrstaPrometaForma.vue?vue&type=script&lang=js&"
export * from "./VrstaPrometaForma.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16ea2f09",
  null
  
)

export default component.exports