var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('breadcrumbs',{attrs:{"title":'Osobe',"breadcrumbs":_vm.allBreadcrumbs}}),_c('nav',{staticClass:"navbar navbar-expand-lg navbar-light",staticStyle:{"background-color":"#2F4050"}},[_c('a',{class:{
                        'navbar-brand btn btn-light btn-xl': true,
                        'active': _vm.active_route === _vm.route
                    },on:{"click":function($event){return _vm.open(_vm.route)}}},[_c('strong',[_vm._v("Osoba")])]),(_vm.osoba)?_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav mr-auto"},[_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                'nav-link btn btn-light': true,
                                'active': _vm.active_route === _vm.route + '/grupe-statusa'
                            },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/grupe-statusa' )}}},[_c('strong',[_vm._v("Grupa")])])]),_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                'nav-link btn btn-light': true,
                                'active': _vm.active_route === _vm.route + '/razdoblja'
                            },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/razdoblja' )}}},[_c('strong',[_vm._v("Razdoblja")])])]),_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                'nav-link btn btn-light': true,
                                'active': _vm.active_route === _vm.route + '/umanjeni-sati'
                            },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/umanjeni-sati' )}}},[_c('strong',[_vm._v("Umanjeni sati")])])]),_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                'nav-link btn btn-light': true,
                                'active': _vm.active_route === _vm.route + '/tvrtke'
                            },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/tvrtke' )}}},[_c('strong',[_vm._v("Tvrtke")])])]),_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                'nav-link btn btn-light': true,
                                'active': _vm.active_route === _vm.route + '/dodatne-aktivnosti'
                            },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/dodatne-aktivnosti' )}}},[_c('strong',[_vm._v("Dodatne aktivnosti")])])]),_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                'nav-link btn btn-light': true,
                                'active': _vm.active_route === _vm.route + '/stavke'
                            },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/stavke' )}}},[_c('strong',[_vm._v("Stavke")])])]),_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                'nav-link btn btn-light': true,
                                'active': _vm.active_route === _vm.route + '/potvrde'
                            },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/potvrde' )}}},[_c('strong',[_vm._v("Potvrde")])])])])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }