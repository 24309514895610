<template>
    <div>
        <breadcrumbs
            :title="'Županije'"
            :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Nova županija</h5>
            </div>
            <div class="ibox-content">
                <zupanija-forma
                    :disabled="false"
                    :id="null"
                    :modal="false"
                    :action="'create'"
                ></zupanija-forma>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import ZupanijaForma from "./ZupanijaForma";
export default {
    name: "ZupanijaCreate",
    components: {
        ZupanijaForma,
        Breadcrumbs
    },
    data(){
        return {
            breadcrumbs: []
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/postavke/zupanije',
                    name: 'Županije'
                },
                {
                    path: '/postavke/zupanije/create',
                    name: 'Nova županija'
                }
            ]
        }
    },
    mounted(){
        this.setBreadcrumbs()
    }
};
</script>

<style scoped>

</style>
