<template>
    <div>
        <div class="vld-parent">
            <loading :active.sync="open_loader"
                     :can-cancel="false"
                     :is-full-page="true">

            </loading>
        </div>
        <forms
            :settings="settings"
            @onFormButtonClick="onFormButtonClick"
            @onInput="onInput"
        ></forms>
    </div>
</template>

<script>
import axios from "axios"
import toastr from 'toastr'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import Forms from "@/components/form/Forms"
export default {
    name: "SkupForma",
    components:{ Forms, Loading },
    props:{
        user: {
            type: Object
        },
        disabled:{
            type: Boolean,
            default: false
        },
        id:{
            default: null
        },
        modal:{
            type: Boolean,
            default: false
        },
        action: {
            type: String
        }
    },
    data(){
        return {
            settings: {},
            errors: {},
            route: '/skupovi',
            url: 'skup',
            data: {},
            open_loader: false,

            poste_select: [],
            porezi_select: [],
            vrste_skupova_select: [],
            podrucja_tema_select: [],
            vrste_sudionika_select: [],
        }
    },

    methods: {

        async init(){

            let vm = this

            vm.open_loader = true

            await vm.fetchPosta()
            await vm.fetchPorez()
            await vm.fetchVrstaSkupa()
            await vm.fetchPodrucjeTeme()
            await vm.fetchVrstaSudionika()


            if ( vm.action === 'edit' || vm.action === 'show' ){

                axios.get( process.env.VUE_APP_API_URL + vm.url + '/' + vm.id )
                    .then( function ( response ) {

                        vm.data.broj_skupa = response.data.broj_skupa
                        vm.data.naziv = response.data.naziv
                        vm.data.datum_i_vrijeme_od = response.data.datum_i_vrijeme_od
                        vm.data.datum_i_vrijeme_do = response.data.datum_i_vrijeme_do
                        vm.data.mjesto = response.data.mjesto
                        vm.data.posta_id = response.data.posta ? response.data.posta.id.toString() : ''
                        vm.data.adresa = response.data.adresa
                        vm.data.vrsta_skupa_id = response.data.vrsta_skupa ? response.data.vrsta_skupa.id.toString() : ''
                        vm.data.podrucje_teme_id = response.data.podrucje_teme ? response.data.podrucje_teme.id.toString() : ''

                        vm.data.vrste_sudionika_id = []
                        response.data.skupovi_vrste_sudionika.forEach( item => {
                            vm.data.vrste_sudionika_id.push(item.vrsta_sudionika ? item.vrsta_sudionika.id.toString() : '' )
                        } )

                        response.data.skupovi_kotizacija_vrste_sudionika.forEach( item => {
                            vm.data['cijena_za_clanove_' + item.vrsta_sudionika_id ] = item.cijena_za_clanove.toString()
                            vm.data['cijena_za_vanjske_' + item.vrsta_sudionika_id ] = item.cijena_za_vanjske
                            vm.data['porez_id_' + item.vrsta_sudionika_id ] = item.porez_id ? item.porez_id.toString() : null
                        })

                        response.data.skupovi_sati_vrste_sudionika.forEach( item => {
                            vm.data['sati_' + item.vrsta_sudionika_id ] = item.sati
                            vm.data['sati_regulativa_' + item.vrsta_sudionika_id ] = item.sati_regulativa
                        })
                        vm.$emit( 'returnData', response.data )
                        vm.setupForm()
                        vm.open_loader = false
                    } )
                    .catch( function ( error ) {
                        vm.open_loader = false
                        if(error instanceof Error) {
                            console.log( 'Error: ' . error);
                        } else {
                            console.log( 'Unexpected response: ' . error);
                        }
                    } )

            } else if ( vm.action === 'create' ) {
                vm.data.vrste_sudionika_id = ['1']
                vm.setupForm()
                vm.open_loader = false
            }
            vm.open_loader = false
        },


        async fetchPosta(){
            let vm = this
            const response = await axios.get(process.env.VUE_APP_API_URL + 'posta' )
            return response.data.data.map( function( item ) {
                vm.poste_select.push(
                    {
                        key: item.id.toString(),
                        value: item.postanski_broj + ' ' + item.mjesto
                    }
                )
            } )
        },

        getPosta( id ){
            return this.poste_select.find( item => item.key === id )
        },

        async fetchPorez(){
            let vm = this
            const response = await axios.get(process.env.VUE_APP_API_URL + 'porez' )
            return response.data.data.map( function( item ) {
                vm.porezi_select.push(
                    {
                        key: item.id.toString(),
                        value: item.postotak
                    }
                )
            } )
        },

        getPorez( id ){
            return this.porezi_select.find( item => item.key === id )
        },

        async fetchVrstaSkupa(){
            let vm = this
            const response = await axios.get(process.env.VUE_APP_API_URL + 'vrsta-skupa' )
            return response.data.data.map( function( item ) {
                vm.vrste_skupova_select.push(
                    {
                        key: item.id.toString(),
                        value: item.naziv
                    }
                )
            } )
        },

        getVrstaSkupa( id ){
            return this.vrste_skupova_select.find( item => item.key === id )
        },

        async fetchPodrucjeTeme(){
            let vm = this
            const response = await axios.get(process.env.VUE_APP_API_URL + 'podrucje-teme' )
            return response.data.data.map( function( item ) {
                vm.podrucja_tema_select.push(
                    {
                        key: item.id.toString(),
                        value: item.naziv
                    }
                )
            } )
        },

        getPodrucjeTeme( id ){
            return this.podrucja_tema_select.find( item => item.key === id )
        },

        async fetchVrstaSudionika(){
            let vm = this
            const response = await axios.get(process.env.VUE_APP_API_URL + 'vrsta-sudionika' )
            response.data.data.map( function( item ) {
                vm.vrste_sudionika_select.push(
                    {
                        key: item.id.toString(),
                        value: item.naziv
                    }
                )
            } )
        },

        getVrstaSudionika( ids ){
            let vm = this
            if( !ids.includes( '1' ) )
                ids.push( '1' )
            let ret = []
            ids.forEach( function ( id ) {
                ret.push( vm.vrste_sudionika_select.find( item => item.key === id ) )
            } )
            return ret
        },

        setupForm(){

            let vm = this;

            vm.buttons = []

            let button_cancel = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
                action: 'redirect', // request
                config: {
                    url: vm.route + ( vm.action === 'edit' ? ( '/' + vm.id) : '' )
                }
            }

            let button_delete = {
                id: 'delete',
                title: 'Obriši',
                type: 'danger',
                action: 'request',
                config: {
                    method: 'DELETE',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let button_edit = {
                id: 'edit',
                title: 'Uredi',
                type: 'success',
                action: 'redirect',
                config: {
                    url: vm.route +  '/' + vm.id +'/edit'
                }
            }

            let button_save = {
                id: 'save',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + vm.url
                }
            }

            let button_update = {
                id: 'update',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'PUT',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let buttons = []


            if( vm.action === 'edit' ){
                buttons.push( button_cancel)
                buttons.push( button_update)
            } else if( vm.action === 'show' ) {
                buttons.push( button_cancel )
                buttons.push( button_delete )
                buttons.push( button_edit )
            }
            else if ( vm.action === 'create' ) {
                buttons.push( button_cancel )
                buttons.push( button_save )
            } else {
                return
            }

            let inputs = [];


            let broj_skupa_input = {
                name: 'broj_skupa',
                type: 'input',
                title: 'Broj skupa',
                value:  vm.data.broj_skupa,
                width: 3,
                error: vm.errors['broj_skupa'] ? vm.errors['broj_skupa'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: true,
                disabled: true,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }

            let naziv_input = {
                name: 'naziv',
                type: 'input',
                title: 'Naziv',
                value:  vm.data.naziv,
                width: 9,
                error: vm.errors['naziv'] ? vm.errors['naziv'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 1,
                    input_width: 11
                }
            }

            let datum_i_vrijeme_od_input = {
                name: 'datum_i_vrijeme_od',
                type: 'datepicker',
                title: 'Datum početka',
                value: vm.data.datum_i_vrijeme_od,
                width: 3,
                error: vm.errors['datum_i_vrijeme_od'] ? vm.errors['datum_i_vrijeme_od'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    mode: 'dateTime',
                    title_width: 4,
                    input_width: 8
                }
            }

            let datum_i_vrijeme_do_input = {
                name: 'datum_i_vrijeme_do',
                type: 'datepicker',
                title: 'Datum završetka',
                value: vm.data.datum_i_vrijeme_do,
                width: 9,
                error: vm.errors['datum_i_vrijeme_do'] ? vm.errors['datum_i_vrijeme_do'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    mode: 'dateTime',
                    title_width: 2,
                    input_width: 3
                }
            }


            let mjesto_input = {
                name: 'mjesto',
                type: 'input',
                title: 'Mjesto',
                value:  vm.data.mjesto,
                width: 4,
                error: vm.errors['mjesto'] ? vm.errors['mjesto'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }


            let posta_id_input = {
                name: 'posta_id',
                type: 'select',
                title: 'Pošta',
                value: vm.data.posta_id ? vm.getPosta( vm.data.posta_id ) : '',
                width: 4,
                error: vm.errors['posta_id'] ? vm.errors['posta_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.poste_select,
                    title_width: 3,
                    input_width: 9
                }
            }


            let adresa_input = {
                name: 'adresa',
                type: 'input',
                title: 'Adresa',
                value: vm.data.adresa,
                width: 4,
                error: vm.errors['adresa'] ? vm.errors['adresa'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 3,
                    input_width: 9
                }
            }


            let vrsta_skupa_id_input = {
                name: 'vrsta_skupa_id',
                type: 'select',
                title: 'Vrsta skupa',
                value: vm.data.vrsta_skupa_id ? vm.getVrstaSkupa( vm.data.vrsta_skupa_id ) : '',
                width: 4,
                error: vm.errors['vrsta_skupa_id'] ? vm.errors['vrsta_skupa_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.vrste_skupova_select,
                    title_width: 3,
                    input_width: 9
                }
            }


            let podrucje_teme_id_input = {
                name: 'podrucje_teme_id',
                type: 'select',
                title: 'Područje teme',
                value: vm.data.podrucje_teme_id ? vm.getPodrucjeTeme( vm.data.podrucje_teme_id ) : '',
                width: 4,
                error: vm.errors['podrucje_teme_id'] ? vm.errors['podrucje_teme_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.podrucja_tema_select,
                    title_width: 3,
                    input_width: 9
                }
            }


            let vrste_sudionika_id_input = {
                name: 'vrste_sudionika_id',
                type: 'select',
                title: 'Vrste sudionika',
                value: vm.getVrstaSudionika( vm.data.vrste_sudionika_id || [] ),
                width: 4,
                error: vm.errors['vrste_sudionika_id'] ? vm.errors['vrste_sudionika_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.vrste_sudionika_select,
                    title_width: 3,
                    input_width: 9,
                    multiple: true
                }
            }



            inputs.push( broj_skupa_input )
            inputs.push( naziv_input )
            inputs.push( datum_i_vrijeme_od_input )
            inputs.push( datum_i_vrijeme_do_input )
            inputs.push( mjesto_input )
            inputs.push( posta_id_input )
            inputs.push( adresa_input )
            inputs.push( vrsta_skupa_id_input )
            inputs.push( podrucje_teme_id_input )
            inputs.push( vrste_sudionika_id_input )


            let main_section = {
                name: ' ',
                width: 12,
                inputs: inputs
            }

            let sections = []

            sections.push( main_section )

            vm.getVrstaSudionika( vm.data.vrste_sudionika_id || [] ).forEach( function( vrsta_sudionika ) {

                let vrsta_sudionika_section = []

                let vrsta_sudionika_inputs = []

                let cijena_za_clanove_input = {
                    name: 'cijena_za_clanove_' + vrsta_sudionika.key,
                    type: 'input',
                    title: 'Cijena za clanove',
                    value:  vm.data['cijena_za_clanove_' + vrsta_sudionika.key ],
                    width: 3,
                    error: vm.errors['cijena_za_clanove_' + vrsta_sudionika.key ] ? vm.errors['cijena_za_clanove_' + vrsta_sudionika.key ] : '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: false,
                    readonly: vm.disabled,
                    disabled: vm.disabled,
                    config: {
                        title_width: 4,
                        input_width: 8
                    }
                }

                let cijena_za_vanjske_input = {
                    name: 'cijena_za_vanjske_' + vrsta_sudionika.key,
                    type: 'input',
                    title: 'Cijena za vanjske',
                    value:  vm.data['cijena_za_vanjske_' + vrsta_sudionika.key ],
                    width: 3,
                    error: vm.errors['cijena_za_vanjske_' + vrsta_sudionika.key ] ? vm.errors['cijena_za_vanjske_' + vrsta_sudionika.key ] : '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: false,
                    readonly: vm.disabled,
                    disabled: vm.disabled,
                    config: {
                        title_width: 4,
                        input_width: 8
                    }
                }



                let sati_input = {
                    name: 'sati_' + vrsta_sudionika.key,
                    type: 'input',
                    title: 'Sati',
                    value:  vm.data['sati_' + vrsta_sudionika.key ],
                    width: 2,
                    error: vm.errors['sati_' + vrsta_sudionika.key ] ? vm.errors['sati_' + vrsta_sudionika.key ] : '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: false,
                    readonly: vm.disabled,
                    disabled: vrsta_sudionika.key === '1' ? vm.disabled : true,
                    config: {
                        title_width: 4,
                        input_width: 8
                    }
                }


                let sati_regulativa_input = {
                    name: 'sati_regulativa_' + vrsta_sudionika.key,
                    type: 'input',
                    title: 'Sati regulativa',
                    value:  vm.data['sati_regulativa_' + vrsta_sudionika.key ],
                    width: 2,
                    error: vm.errors['sati_regulativa_' + vrsta_sudionika.key ] ? vm.errors['sati_regulativa_' + vrsta_sudionika.key ] : '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: false,
                    readonly: vm.disabled,
                    disabled: vrsta_sudionika.key === '1' ? vm.disabled : true,
                    config: {
                        title_width: 4,
                        input_width: 8
                    }
                }


                let porez_id_input = {
                    name: 'porez_id_' + vrsta_sudionika.key,
                    type: 'select',
                    title: 'Porez',
                    value: vm.getPorez( vm.data[ 'porez_id_' + vrsta_sudionika.key ] ),
                    width: 2,
                    error: vm.errors['porez_id_' + vrsta_sudionika.key] ? vm.errors['porez_id_' + vrsta_sudionika.key ] : '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: false,
                    readonly: vm.disabled,
                    disabled: vm.disabled,
                    config: {
                        options: vm.porezi_select,
                        title_width: 4,
                        input_width: 8,
                    }
                }

                //vrsta_sudionika_inputs.push( vrsta_sudionika_input )
                vrsta_sudionika_inputs.push( cijena_za_clanove_input )
                vrsta_sudionika_inputs.push( cijena_za_vanjske_input )
                vrsta_sudionika_inputs.push( porez_id_input )
                vrsta_sudionika_inputs.push( sati_input )
                vrsta_sudionika_inputs.push( sati_regulativa_input )

                vrsta_sudionika_section = {
                    name: vrsta_sudionika.value,
                    width: 12,
                    inputs: vrsta_sudionika_inputs
                }

                sections.push( vrsta_sudionika_section )

            } );



            let settings = {
                width: 12,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: sections
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },

        /**
         *  on input return data
         */
        onInput( name, value ){

            let vm = this;

            if( vm.data[name] !== value )
                vm.data[name] = value

            vm.setupForm()
        },

        /**
         *  on button click
         */
        onFormButtonClick( id, action, config, data  ){
            if( id === 'save' || id === 'update' ){
                if( data.success){
                    if( id == 'save' )
                        toastr.success( 'Uspješno spremljeno!' )
                    else if ( id == 'update' )
                        toastr.success( 'Uspješno ažurirano!' )
                    this.$router.push({ path: this.route + '/' + data.data.id } )
                } else {
                    this.errors = data.messages
                    this.setupForm()
                }
            } else if( id == 'delete' ){
                toastr.error( 'Uspješno obrisano!' )
                this.$router.push({ path: this.route } )
            }
        },


    },
    mounted() {
        this.init()
    }

};
</script>

<style scoped>

</style>
