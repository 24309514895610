<template>
    <div>
        <skupovi-navigation
            :id="skup_id"
            :breadcrumbs="breadcrumbs"
        >
        </skupovi-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Nepreneseni - Excel </h5><br>
            </div>
            <div class="ibox-content">

                <div v-for="( item, index ) in data " :key="index">

                    <table class="table">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>OIB ({{ item['stavka_ucitani_dokument']['ucitani_dokument']['naziv'] }})</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(oib, i) in item['oibs']">
                            <td>{{ i + 1 }}</td>
                            <td>{{ oib }}</td>
                        </tr>
                        </tbody>
                    </table>

                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Datatables from "../../../../components/datatable/Datatables";
import SkupoviNavigation from "../SkupNavigation";
import axios from "axios";
import toastr from "toastr";
export default {
    name: "SkupStavkeNotImportedExcel",
    components: { SkupoviNavigation },
    data(){
        return {
            breadcrumbs: [],
            skup_id: this.$route.params.skup_id,
            data: []
        }
    },
    methods:{

        fetchData(){
            let vm = this
            vm.open_loader = true
            axios({
                method: 'GET',
                url: process.env.VUE_APP_API_URL + 'stavka/not-imported',
                params: {
                    skup_id : vm.skup_id
                }
            }).then((response) => {
                vm.open_loader = false
                vm.data = response.data
            }, (error) => {
                vm.open_loader = false
                console.log(error);
            });
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/skupovi/' + this.skup_id + '/stavke',
                    name: 'Stavke'
                },
                {
                    path: '/skupovi/' + this.skup_id + '/stavke',
                    name: 'Nepreneseni - Excel'
                }
            ]
        }
    },
    mounted() {
        this.setBreadcrumbs()
        this.fetchData()
    }
}
</script>

<style scoped>

</style>
