<template>
    <div class="row">
        <label :class="title_width"  :for="name" >
            <strong class="float-lg-right float-md-left">{{title}} {{ required ? '*' : ''}}</strong>
        </label>

        <div :class="input_width">
            <span class="form-text m-b-none text-success">
                    {{ note }}
            </span>
            <div class="input-group-append">
                <div
                    class="jumbotron"
                    style="width: 100%; padding-top: 10px; padding-bottom: 10px; min-height: 200px"
                    @dragover.prevent="dragover"
                    @dragleave.prevent="dragleave"
                    @drop.prevent="drop"
                >

                    <div style="text-align: center">
                        <label class="font-weight-bold" @click.self.prevent >
                            <input type="file"
                                   :ref="name"
                                   @change="onChange"
                                   multiple
                                   style="display: none"
                            />
                            <h3>Dovucite datoteku ili kliknite <span class="text-success" style="text-decoration: underline; cursor: pointer">ovdje</span></h3>
                        </label>
                    </div>

                    <div class="row">
                        <button
                            v-for="(file, index) in ret_value"
                            :key="index"
                            class="btn btn-xs btn-success"
                            style="padding-left: 10px; padding-right: 10px; margin-left: 5px; margin-right: 5px; margin-bottom: 10px; cursor: default"
                        >
                            {{ file.name }}
                            <i
                                @click="remove( index )"
                                class="text-danger"
                                style="margin-left: 5px; margin-right: 5px; cursor: pointer;"
                            >
                                <span class="fa fa-times"></span>
                            </i>
                            <i
                                v-if="file.url"
                                @click="download(index)"
                                style="margin-left: 5px; margin-right: 5px; cursor: pointer"
                            >
                                <span class="fa fa-download"></span>
                            </i>
                        </button>

                    </div>

                </div>
                <span
                    v-if="explanation"
                    class="input-group-addon"
                    data-toggle="tooltip"
                    data-placement="right"
                    :title="explanation"
                >
                    <i class="fa fa-info" aria-hidden="true"></i>
                </span>
            </div>
            <span class="form-text m-b-none text-danger">
                <div class="row">
                    <div class="col-lg-6">
                    {{ error[0] }}
                    </div>
                    <!--                <div class="col-md-6">-->
                    <!--                    <button class="btn float-right" style="margin-left: 2px; margin-right: 2px">123</button>-->
                    <!--                </div>-->
                </div>
            </span>
        </div>

    </div>
</template>

<script>

import axios from "axios";

export default {
    name: "UploadComponent",
    props: [
        'name',
        'value',
        'title',
        'error',
        'note',
        'explanation',
        'placeholder',
        'required',
        'readonly',
        'disabled',
        'config'
    ],
    data(){
        return {
            ret_value: [],
            disabled_input: this.disabled,
            title_width: ('col-form-label col-lg-' + ( this.config.title_width ? this.config.title_width : '4' ) ),
            input_width: ('col-lg-' + ( this.config.input_width ? this.config.input_width : '8' ) ),
        }
    },
    methods:{
        _return() {
            this.$emit('onInput', this.name, this.ret_value );
        },

        getBase64( file ){
            return new Promise(( resolve, reject ) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            } )
        },

        async onChange(){
            let vm = this
            Object.keys( vm.$refs[vm.name].files ).forEach( function( index ) {
                var file = vm.$refs[vm.name].files[index]
                vm.getBase64( file ).then( blob => {
                    vm.ret_value.push( {
                        name: file.name,
                        size: file.size,
                        type: file.type,
                        blob: blob,
                        url: ''
                    })
                })
            } )
            this._return()
        },
        remove( i ){
            this.ret_value.splice( i, 1 )
            this._return()
        },
        dragover(){
            // change color of background
        },
        dragleave(){
            // change color of background
        },
        drop( event ){
            this.$refs[this.name].files = event.dataTransfer.files
            this.onChange()
            this.dragleave()
        },
        download( i ){
            let vm = this
            axios( {
                method: 'GET',
                responseType: 'blob',
                url: vm.ret_value[i].url
            }).then( ( response )=> {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', vm.ret_value[i].name )
                document.body.appendChild(link)
                link.click()
                vm.open_loader = false
            }, ( error )=> {
                console.log(error);
            })
        }
    },
    mounted() {
        this.ret_value = this.value || []
        this._return()
        this.$emit( 'returnComponent', this.name, this )
    },
    watch: {
        value(){
            this.ret_value = this.value || []
            this._return()
        }
    }
}
</script>

<style scoped>
</style>
