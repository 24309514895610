<template>
    <div>
        <skupovi-navigation
            :id="id"
            :breadcrumbs="breadcrumbs"
        >
        </skupovi-navigation>


        <div v-show="show_skup" class="ibox ">
            <div class="ibox-title">
                <h5>Skup: {{ naziv }} </h5>
            </div>
            <div class="ibox-content">
                <skup-forma
                    :disabled="true"
                    :id="id"
                    :modal="false"
                    :action="'show'"
                    @returnData="setData"
                >
                </skup-forma>
            </div>
        </div>
    </div>
</template>

<script>
import SkupoviNavigation from "./SkupNavigation";
import SkupForma from "./SkupForma";
export default {
    name: "SkupDetails",
    components: { SkupForma, SkupoviNavigation },
    props: ['user'],
    data(){
        return {
            id: this.$route.params.skup_id,
            breadcrumbs: [],
            naziv: '',
            show_skup: true,
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/skupovi',
                    name: 'Skupovi'
                },
                {
                    path: ( '/skupovi/' + this.id ),
                    name: ( 'Skup: ' + this.naziv )
                }
            ]
        },
        setData( data ){
            this.naziv = data.naziv
            this.setBreadcrumbs()
        }
    },
    mounted() {
    }
};
</script>

<style scoped>

</style>
