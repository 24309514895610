<template>
    <div>
        <breadcrumbs
            :title="'Države'"
            :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Grupe statusa</h5>
                <br>
                <!--<button
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click.prevent="create"
                >
                    Nova grupa statusa
                </button>-->
            </div>

        </div>
        <div class="ibox-content">
            <datatables
                v-if="show_datatable"
                :url="datatables_url"
                :settings="datatables.settings"
                :title="datatables.title"
                @rowClick="rowClick"
            ></datatables>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import Datatables from "../../../../components/datatable/Datatables";
export default {
    name: "GrupeStatusa",
    components: { Datatables, Breadcrumbs },
    data(){
        return {
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL + 'grupa-statusa',
            datatables: {},
            breadcrumbs: []
        }
    },
    methods: {
        rowClick( row ){
            //this.$router.push( { path: '/postavke/grupe-statusa/' + row.id } )
        },

        create(){
            this.$router.push( { path: '/postavke/grupe-statusa/create' } )
        },

        setDatatable(){
            let vm = this

            vm.datatables = {
                settings: {
                    table_header: [
                        {
                            'id': 'id',
                            'visible': false
                        },
                        {
                            'id': 'naziv',
                            'title': 'Naziv',
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'grupa-statusa',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'kratki_naziv',
                            'title': 'Kratki naziv',
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'grupa-statusa',
                                'visible' : true
                            }
                        },
                        /*{
                            'id': 'aktivna',
                            'title': 'Aktivna',
                            'type': function(row) {
                                return row.aktivna && row.aktivna == '1' ? 'Da' : 'Ne'
                            },
                            'filter': {
                                'type': 'select',
                                'search': [
                                    {
                                        key: '1',
                                        value: 'Da'
                                    },
                                    {
                                        key: '0',
                                        value: 'Ne'
                                    }
                                ],
                                'value': null
                            },
                            'column_visibility_group': {
                                id: 'grupa-statusa',
                            },
                        }*/

                    ],
                    column_visibility_groups:{
                        'grupa-statusa' : 'Grupa statusa'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Grupa statusa'
            }

            vm.show_datatable = true;
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/postavke/grupe-statusa',
                    name: 'Grupa statusa'
                }
            ]
        }
    },
    mounted() {
        this.setDatatable()
        this.setBreadcrumbs()
    }

};
</script>

<style scoped>

</style>
