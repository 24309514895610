import { render, staticRenderFns } from "./SkupPrijave.vue?vue&type=template&id=175f4e49&scoped=true&"
import script from "./SkupPrijave.vue?vue&type=script&lang=js&"
export * from "./SkupPrijave.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "175f4e49",
  null
  
)

export default component.exports