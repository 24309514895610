<template>
    <div>
        <osobe-navigation
            :id="osoba_id"
            :breadcrumbs="breadcrumbs"
        >
        </osobe-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Grupe statusa</h5><br>
                <button
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click.prevent="create"
                >
                    Novi unos
                </button>
            </div>
            <div class="ibox-content">
                <datatables
                    v-if="show_datatable"
                    :url="datatables_url"
                    :settings="datatables.settings"
                    :title="datatables.title"
                    @rowClick="rowClick"
                >
                </datatables>
            </div>
        </div>
    </div>
</template>

<script>
import Datatables from "../../../../components/datatable/Datatables";
import OsobeNavigation from "../OsobeNavigation";
export default {
    name: "OsobaGrupaStatusa",
    components: { OsobeNavigation, Datatables },
    data(){
        return {
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL +  'osoba-tvrtka',
            datatables: {},
            breadcrumbs: [],
            osoba_id: this.$route.params.osoba_id
        }
    },
    methods:{
        rowClick( row ){
            this.$router.push( { path: '/osobe/' + this.osoba_id + '/tvrtke/' + row.id } )
        },

        create(){
            this.$router.push( { path: '/osobe/' + this.osoba_id + '/tvrtke/create' } )
        },

        setDatatable() {
            let vm = this;
            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'osoba_id',
                            'visible': false,
                            'filter':{
                                'type': 'none',
                                'value': vm.osoba_id
                            },
                        },
                        {
                            'id': 'tvrtka_naziv',
                            'title': 'Naziv',
                            'type' : function( row ){
                                return row.tvrtka ? row.tvrtka.naziv : ''
                            },
                            'column_visibility_group': {
                                'id': 'osoba-tvrtka',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'email',
                            'title': 'Email',
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'osoba-tvrtka',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'mobitel',
                            'title': 'Mobitel',
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'osoba-tvrtka',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'telefon',
                            'title': 'Telefon',
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'osoba-tvrtka',
                                'visible' : true
                            }
                        },


                    ],
                    column_visibility_groups:{
                        'osoba-tvrtka' : 'Tvrtke'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Tvrtke',
            }
            vm.show_datatable = true;
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/osobe/' + this.osoba_id + '/tvrtke',
                    name: 'Tvrtke'
                }
            ]
        }
    },
    mounted() {
        this.setDatatable()
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>
