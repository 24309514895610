import { render, staticRenderFns } from "./ZupanijaCreate.vue?vue&type=template&id=4363487c&scoped=true&"
import script from "./ZupanijaCreate.vue?vue&type=script&lang=js&"
export * from "./ZupanijaCreate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4363487c",
  null
  
)

export default component.exports