import { render, staticRenderFns } from "./VrstaSudionikaForma.vue?vue&type=template&id=4795d096&scoped=true&"
import script from "./VrstaSudionikaForma.vue?vue&type=script&lang=js&"
export * from "./VrstaSudionikaForma.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4795d096",
  null
  
)

export default component.exports