<template>
    <div>
        <breadcrumbs
            :title="'Države'"
            :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Grupa statusa: {{ naziv }}</h5>
            </div>
            <div class="ibox-content">
                <grupa-statusa-forma
                    :disabled="true"
                    :id="id"
                    :modal="false"
                    :action="'show'"
                    @returnData="setData"
                ></grupa-statusa-forma>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import GrupaStatusaForma from "./GrupaStatusaForma";
export default {
    name: "GrupaStatusaDetails",
    components: {
        GrupaStatusaForma,
        Breadcrumbs
    },
    data(){
        return {
            id: this.$route.params.grupa_statusa_id,
            breadcrumbs: [],
            naziv: ''
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/postavke/grupe-statusa',
                    name: 'Grupe statusa'
                },
                {
                    path: '/postavke/grupe-statusa/' + this.id,
                    name: 'Grupa statusa: ' + this.naziv
                }
            ]
        },
        setData( data ){
            this.naziv = data.naziv ? ( data.kratki_naziv + ' ' + data.naziv ) : ''
            this.setBreadcrumbs()
        }
    }
};
</script>

<style scoped>

</style>
