<template>
    <div>
        <skupovi-navigation
            :id="skup_id"
            :breadcrumbs="breadcrumbs"
        >
        </skupovi-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Nova podtema</h5>
            </div>
            <div class="ibox-content">
                <skup-tema2-forma
                    :disabled="false"
                    :id="null"
                    :modal="false"
                    :action="'create'"
                >
                </skup-tema2-forma>
            </div>
        </div>
    </div>
</template>

<script>
import SkupoviNavigation from "../SkupNavigation";
import SkupTema2Forma from "./SkupTema2Forma";
export default {
    name: "SkupTema2Create",
    components: { SkupTema2Forma, SkupoviNavigation },
    data(){
        return {
            breadcrumbs: [],
            skup_id: this.$route.params.skup_id
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/skupovi/' + this.skup_id + '/teme2',
                    name: 'Podteme'
                },
                {
                    path: '/skupovi/' + this.skup_id + '/teme2/create',
                    name: 'Nova podtema'
                }
            ]
        }
    }
};
</script>

<style scoped>

</style>
