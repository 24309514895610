<template>
    <div>
        <osobe-navigation
            :id="osoba_id"
            :breadcrumbs="breadcrumbs"
        >
        </osobe-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Stavke</h5><br>
            </div>
            <div class="ibox-content">
                <datatables
                    v-if="show_datatable"
                    :url="datatables_url"
                    :settings="datatables.settings"
                    :title="datatables.title"
                >
                </datatables>
            </div>
        </div>
    </div>
</template>

<script>
import Datatables from "../../../../components/datatable/Datatables";
import OsobeNavigation from "../OsobeNavigation";
export default {
    name: "OsobaStavke",
    components: { OsobeNavigation, Datatables },
    data(){
        return {
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL +  'stavka',
            datatables: {},
            breadcrumbs: [],
            osoba_id: this.$route.params.osoba_id
        }
    },
    methods:{

        setDatatable() {
            let vm = this;
            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'osoba_id',
                            'visible': false,
                            'filter':{
                                'type': 'none',
                                'value': vm.osoba_id
                            },
                        },
                        {
                            'id': 'skup_broj_skupa',
                            'title': 'Broj skupa',
                            'type' : function( row ){
                                return row.skup ? row.skup.broj_skupa : ''
                            },
                            'column_visibility_group': {
                                'id': 'stavke',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'skup_naziv',
                            'title': 'Naziv skupa',
                            'type' : function( row ){
                                return row.skup ? row.skup.naziv : ''
                            },
                            'column_visibility_group': {
                                'id': 'stavke',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'skup_datum_i_vrijeme_od',
                            'title': 'Datum početka',
                            'type' : function( row ){
                                return row.skup ? row.skup.datum_i_vrijeme_od : ''
                            },
                            'column_visibility_group': {
                                'id': 'stavke',
                                'visible' : true
                            },
                            'filter': {
                                'type': 'date',
                            }
                        },
                        {
                            'id': 'skup_datum_i_vrijeme_do',
                            'title': 'Datum završetka',
                            'type' : function( row ){
                                return row.skup ? row.skup.datum_i_vrijeme_do : ''
                            },
                            'column_visibility_group': {
                                'id': 'stavke',
                                'visible' : true
                            },
                            'filter': {
                                'type': 'date',
                            }
                        },
                        {
                            'id': 'vrsta_sudionika_naziv',
                            'title': 'Status sudionika',
                            'type' : function( row ){
                                return row.vrsta_sudionika ? row.vrsta_sudionika.naziv : ''
                            },
                            'column_visibility_group': {
                                'id': 'stavke',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'tema_naziv',
                            'title': 'Tema',
                            'type' : function( row ){
                                return row.tema ? row.tema.naziv : ''
                            },
                            'column_visibility_group': {
                                'id': 'stavke',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'tema2_naziv',
                            'title': 'Podtema',
                            'type' : function( row ){
                                return row.tema2 ? row.tema2.naziv : ''
                            },
                            'column_visibility_group': {
                                'id': 'stavke',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'datum',
                            'title': 'Datum stavke',
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'stavke',
                                'visible' : true
                            },
                            'filter': {
                                'type': 'date',
                            }
                        },
                        {
                            'id': 'sati',
                            'title': 'Sati',
                            'searchable' : false,
                            'type' : function( row ){
                                return row.sati.toString()
                            },
                            'column_visibility_group': {
                                'id': 'stavke',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'sati_regulativa',
                            'title': 'Sati regulativa',
                            'searchable' : false,
                            'type' : function( row ){
                                return row.sati_regulativa.toString()
                            },
                            'column_visibility_group': {
                                'id': 'stavke',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'podrucje_teme_naziv',
                            'title': 'Područje teme',
                            'type' : function( row ){
                                if( row.tema2 ){
                                    return row.tema2.podrucje_teme ? row.tema2.podrucje_teme.naziv : ''
                                } else if ( row.tema ){
                                    return row.tema.podrucje_teme ? row.tema.podrucje_teme.naziv : ''
                                } else {
                                    return row.skup.podrucje_teme ? row.skup.podrucje_teme.naziv : ''
                                }
                            },
                            'column_visibility_group': {
                                'id': 'stavke',
                                'visible' : true
                            }
                        },


                    ],
                    column_visibility_groups:{
                        'stavke' : 'Stavke'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Stavke',
            }
            vm.show_datatable = true;
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/osobe/' + this.osoba_id + '/stavke',
                    name: 'Stavke'
                }
            ]
        }
    },
    mounted() {
        this.setDatatable()
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>
