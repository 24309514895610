<template>
    <div>
        <breadcrumbs
            :title="'Grupe statusa'"
            :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Nova grupa statusa</h5>
            </div>
            <div class="ibox-content">
                <grupa-statusa-forma
                    :disabled="false"
                    :id="null"
                    :modal="false"
                    :action="'create'"
                ></grupa-statusa-forma>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import GrupaStatusaForma from "./GrupaStatusaForma";
export default {
    name: "GrupaStatusaCreate",
    components: {
        GrupaStatusaForma,
        Breadcrumbs
    },
    data(){
        return {
            breadcrumbs: []
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/postavke/grupe-statusa',
                    name: 'Grupe statusa'
                },
                {
                    path: '/postavke/grupe-statusa/create',
                    name: 'Nova grupa statusa'
                }
            ]
        }
    },
    mounted() {
        this.setBreadcrumbs()
    }
};
</script>

<style scoped>

</style>
