<template>
    <div>
        <div class="vld-parent">
            <loading :active.sync="open_loader"
                     :can-cancel="false"
                     :is-full-page="true">

            </loading>
        </div>
        <forms
            :settings="settings"
            @onFormButtonClick="onFormButtonClick"
            @onInput="onInput"
        ></forms>
    </div>
</template>

<script>
import axios from "axios"
import toastr from 'toastr'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import Forms from "@/components/form/Forms"
export default {
    name: "OsobaForma",
    components:{ Forms, Loading },
    props:{
        user: {
            type: Object
        },
        disabled:{
            type: Boolean,
            default: false
        },
        id:{
            default: null
        },
        modal:{
            type: Boolean,
            default: false
        },
        action: {
            type: String
        }
    },
    data(){
        return {
            settings: {},
            errors: {},
            route: '/osobe',
            url: 'osoba',
            data: {},
            open_loader: false,

            poste_select: [],
            spol_select: [
                {
                    key: '0',
                    value: 'Ž'
                },
                {
                    key: '1',
                    value: 'M'
                }
            ]
        }
    },

    methods: {

        async init(){



            let vm = this

            await vm.fetchPosta()


            if ( vm.action === 'edit' || vm.action === 'show' ){

                axios.get( process.env.VUE_APP_API_URL + vm.url + '/' + vm.id )
                    .then( function ( response ) {

                        vm.data.ime = response.data.ime
                        vm.data.prezime = response.data.prezime
                        vm.data.oib = response.data.oib
                        vm.data.datum_rodjenja = response.data.datum_rodjenja
                        vm.data.posta_id =  response.data.posta_id ? response.data.posta_id.toString() : null
                        vm.data.adresa = response.data.adresa
                        vm.data.email = response.data.email
                        vm.data.telefon = response.data.telefon
                        vm.data.mobitel = response.data.mobitel
                        vm.data.spol = response.data.spol.toString()
                        vm.data.strucni_stupanj = response.data.strucni_stupanj
                        vm.data.akademski_stupanj = response.data.akademski_stupanj
                        vm.data.urudzbeni_broj = response.data.urudzbeni_broj
                        vm.data.klasa = response.data.klasa
                        vm.data.datum_strucnog_ispita = response.data.datum_strucnog_ispita
                        vm.data.datum_izdavanja = response.data.datum_izdavanja
                        vm.data.broj = response.data.broj
                        vm.data.pin = response.data.pin

                        vm.$emit( 'returnData', response.data )
                        vm.setupForm()
                    } )
                    .catch( function ( error ) {
                        if(error instanceof Error) {
                            console.log( 'Error: ' . error);
                        } else {
                            console.log( 'Unexpected response: ' . error);
                        }
                    } )

            } else if ( vm.action === 'create' ) {
                vm.setupForm()
            }
        },


        async fetchPosta(){
            let vm = this
            const response = await axios.get(process.env.VUE_APP_API_URL + 'posta' )
            return response.data.data.map( function( item ) {
                vm.poste_select.push(
                    {
                        key: item.id.toString(),
                        value: item.postanski_broj + ' ' + item.mjesto
                    }
                )
            } )
        },

        getPosta( id ){
            return this.poste_select.find( item => item.key === id )
        },

        getSpol( id ){
            return this.spol_select.find( item => item.key === id )
        },


        setupForm(){

            let vm = this;

            vm.buttons = []

            let button_cancel = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
                action: 'redirect', // request
                config: {
                    url: vm.route + ( vm.action === 'edit' ? ( '/' + vm.id) : '' )
                }
            }

            let button_delete = {
                id: 'delete',
                title: 'Obriši',
                type: 'danger',
                action: 'request',
                config: {
                    method: 'DELETE',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let button_edit = {
                id: 'edit',
                title: 'Uredi',
                type: 'success',
                action: 'redirect',
                config: {
                    url: vm.route +  '/' + vm.id +'/edit'
                }
            }

            let button_save = {
                id: 'save',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + vm.url
                }
            }

            let button_update = {
                id: 'update',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'PUT',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let buttons = []


            if( vm.action === 'edit' ){
                buttons.push( button_cancel)
                buttons.push( button_update)
            } else if( vm.action === 'show' ) {
                buttons.push( button_cancel )
                buttons.push( button_delete )
                buttons.push( button_edit )
            }
            else if ( vm.action === 'create' ) {
                buttons.push( button_cancel )
                buttons.push( button_save )
            } else {
                return
            }

            let inputs = [];



            let ime_input = {
                name: 'ime',
                type: 'input',
                title: 'Ime',
                value:  vm.data.ime,
                width: 4,
                error: vm.errors['ime'] ? vm.errors['ime'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }
            inputs.push( ime_input )

            let prezime_input = {
                name: 'prezime',
                type: 'input',
                title: 'Prezime',
                value:  vm.data.prezime,
                width: 4,
                error: vm.errors['prezime'] ? vm.errors['prezime'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }
            inputs.push( prezime_input )

            let oib_input = {
                name: 'oib',
                type: 'input',
                title: 'OIB',
                value:  vm.data.oib,
                width: 4,
                error: vm.errors['oib'] ? vm.errors['oib'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }
            inputs.push( oib_input )

            let spol_input = {
                name: 'spol',
                type: 'select',
                title: 'Spol',
                value: vm.getSpol( vm.data.spol ),
                width: 4,
                error: vm.errors['spol'] ? vm.errors['spol'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.spol_select,
                    title_width: 4,
                    input_width: 8
                }
            }
            inputs.push( spol_input )

            let strucni_stupanj_input = {
                name: 'strucni_stupanj',
                type: 'input',
                title: 'Stručni stupanj',
                value: vm.data.strucni_stupanj,
                width: 4,
                error: vm.errors['strucni_stupanj'] ? vm.errors['strucni_stupanj'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }
            inputs.push( strucni_stupanj_input )

            let akademski_stupanj_input = {
                name: 'akademski_stupanj',
                type: 'input',
                title: 'Akademski stupanj',
                value: vm.data.akademski_stupanj,
                width: 4,
                error: vm.errors['akademski_stupanj'] ? vm.errors['akademski_stupanj'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }
            inputs.push( akademski_stupanj_input )

            let datum_rodjenja_input = {
                name: 'datum_rodjenja',
                type: 'datepicker',
                title: 'Datum rođenja',
                value: vm.data.datum_rodjenja,
                width: 4,
                error: vm.errors['datum_rodjenja'] ? vm.errors['datum_rodjenja'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    mode: 'date',
                    title_width: 4,
                    input_width: 8
                }
            }
            inputs.push( datum_rodjenja_input )

            let posta_input = {
                name: 'posta_id',
                type: 'select',
                title: 'Pošta',
                value: vm.getPosta( vm.data.posta_id ),
                width: 4,
                error: vm.errors['posta_id'] ? vm.errors['posta_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.poste_select,
                    title_width: 4,
                    input_width: 8
                }
            }
            inputs.push( posta_input )

            let adresa_input = {
                name: 'adresa',
                type: 'input',
                title: 'Adresa',
                value:  vm.data.adresa,
                width: 4,
                error: vm.errors['adresa'] ? vm.errors['adresa'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }
            inputs.push( adresa_input )

            let email_input = {
                name: 'email',
                type: 'input',
                title: 'Email',
                value:  vm.data.email,
                width: 4,
                error: vm.errors['email'] ? vm.errors['email'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }
            inputs.push( email_input )

            let telefon_input = {
                name: 'telefon',
                type: 'input',
                title: 'Telefon',
                value: vm.data.telefon,
                width: 4,
                error: vm.errors['telefon'] ? vm.errors['telefon'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }
            inputs.push( telefon_input )

            let mobitel_input = {
                name: 'mobitel',
                type: 'input',
                title: 'Mobitel',
                value: vm.data.mobitel,
                width: 4,
                error: vm.errors['mobitel'] ? vm.errors['mobitel'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }
            inputs.push( mobitel_input )

            let urudzbeni_broj_input = {
                name: 'urudzbeni_broj',
                type: 'input',
                title: 'Uruđbeni broj',
                value: vm.data.urudzbeni_broj,
                width: 4,
                error: vm.errors['urudzbeni_broj'] ? vm.errors['urudzbeni_broj'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }
            inputs.push( urudzbeni_broj_input )

            let klasa_input = {
                name: 'klasa',
                type: 'input',
                title: 'Klasa',
                value: vm.data.klasa,
                width: 4,
                error: vm.errors['klasa'] ? vm.errors['klasa'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }
            inputs.push( klasa_input )

            let datum_strucnog_ispita_input = {
                name: 'datum_strucnog_ispita',
                type: 'datepicker',
                title: 'Datum stručnog ispita',
                value: vm.data.datum_strucnog_ispita,
                width: 4,
                error: vm.errors['datum_strucnog_ispita'] ? vm.errors['datum_strucnog_ispita'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    mode: 'date',
                    title_width: 4,
                    input_width: 8
                }
            }
            inputs.push( datum_strucnog_ispita_input )

            let datum_izdavanja_input = {
                name: 'datum_izdavanja',
                type: 'datepicker',
                title: 'Datum izdavanja',
                value: vm.data.datum_izdavanja,
                width: 4,
                error: vm.errors['datum_izdavanja'] ? vm.errors['datum_izdavanja'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    mode: 'date',
                    title_width: 4,
                    input_width: 8
                }
            }
            inputs.push( datum_izdavanja_input )

            /*let broj_input = {
                name: 'broj',
                type: 'input',
                title: 'Broj',
                value: vm.data.broj,
                width: 4,
                error: vm.errors['broj'] ? vm.errors['broj'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }
            inputs.push( broj_input )

            let pin_input = {
                name: 'pin',
                type: 'input',
                title: 'Pin',
                value: vm.data.pin,
                width: 4,
                error: vm.errors['pin'] ? vm.errors['pin'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }
            inputs.push( pin_input )*/

            let main_section = {
                name: ' ',
                width: 12,
                inputs: inputs
            }

            let sections = []

            sections.push( main_section )

            let settings = {
                width: 12,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: sections
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },

        /**
         *  on input return data
         */
        onInput( name, value ){

            let vm = this;

            if( vm.data[name] !== value )
                vm.data[name] = value

            vm.setupForm()
        },

        /**
         *  on button click
         */
        onFormButtonClick( id, action, config, data  ){
            if( id === 'save' || id === 'update' ){
                if( data.success){
                    if( id == 'save' )
                        toastr.success( 'Uspješno spremljeno!' )
                    else if ( id == 'update' )
                        toastr.success( 'Uspješno ažurirano!' )
                    this.$router.push({ path: this.route + '/' + data.data.id } )
                } else {
                    this.errors = data.messages
                    this.setupForm()
                }
            } else if( id == 'delete' ){
                toastr.error( 'Uspješno obrisano!' )
                this.$router.push({ path: this.route } )
            }
        },


    },
    mounted() {
        this.init()
    }

};
</script>

<style scoped>

</style>
