<template>
    <div>
        <breadcrumbs
            :title="'Pošte'"
            :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Nova pošta</h5>
            </div>
            <div class="ibox-content">
                <posta-forma
                    :disabled="false"
                    :id="null"
                    :modal="false"
                    :action="'create'"
                ></posta-forma>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import PostaForma from "./PostaForma";
export default {
    name: "PostaCreate",
    components: {
        PostaForma,
        Breadcrumbs
    },
    data(){
        return {
            breadcrumbs: []
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/postavke/poste',
                    name: 'Pošte'
                },
                {
                    path: '/postavke/porezi/create',
                    name: 'Nova pošta'
                }
            ]
        },
    },
    mounted() {
        this.setBreadcrumbs()
    }
};
</script>

<style scoped>

</style>
