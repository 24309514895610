import { render, staticRenderFns } from "./VrstaSuorganizatoraDetails.vue?vue&type=template&id=0c3e700d&scoped=true&"
import script from "./VrstaSuorganizatoraDetails.vue?vue&type=script&lang=js&"
export * from "./VrstaSuorganizatoraDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c3e700d",
  null
  
)

export default component.exports