<template>
    <div>
        <forms
            :settings="settings"
            @onFormButtonClick="onFormButtonClick"
        ></forms>
    </div>
</template>

<script>
import Forms from "../../../../components/form/Forms";
import axios from "axios";
import toastr from "toastr";
export default {
    name: "VrstaPrometaForma",
    components: { Forms },
    props:{
        disabled: {
            type: Boolean,
            default: false
        },
        id: {
            default:null
        },
        modal: {
            type: Boolean,
            default: false,
        },
        action: {
            type: String
        }
    },
    data(){
        return {
            settings: {},
            errors: {},
            route: '/postavke/vrste-prometa',
            url: 'vrsta-prometa'
        }
    },
    methods: {
        init(){
            let vm = this
            if ( vm.action === 'edit' || vm.action === 'show' ){

                axios.get( process.env.VUE_APP_API_URL + vm.url + '/' + vm.id )
                    .then( function ( response ) {
                        vm.data = response.data
                        vm.$emit( 'returnData', response.data )
                        vm.setupForm()
                    } )
                    .catch( function ( error ) {
                        if(error instanceof Error) {
                            console.log( 'Error: ' . error);
                        } else {
                            console.log( 'Unexpected response: ' . error);
                        }
                    } )

            } else if ( vm.action === 'create' ) {
                vm.data = {}
                vm.setupForm()
            } else {
                return
            }
        },

        setupForm(){
            let vm = this;

            vm.buttons = [];

            let button_cancel = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
                action: 'redirect', // request
                config: {
                    url: vm.route + ( vm.action === 'edit' ? ( '/' + vm.id) : '' )
                }
            }

            let button_delete = {
                id: 'delete',
                title: 'Obriši',
                type: 'danger',
                action: 'request',
                config: {
                    method: 'DELETE',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let button_edit = {
                id: 'edit',
                title: 'Uredi',
                type: 'success',
                action: 'redirect',
                config: {
                    url: vm.route +  '/' + vm.id +'/edit'
                }
            }

            let button_save = {
                id: 'save',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + vm.url
                }
            }

            let button_update = {
                id: 'update',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'PUT',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let buttons = []


            if( vm.action === 'edit' ){
                buttons.push( button_cancel)
                buttons.push( button_update)
            } else if( vm.action === 'show' ) {
                buttons.push( button_cancel )
                buttons.push( button_delete )
                buttons.push( button_edit )
            }
            else if ( vm.action === 'create' ) {
                buttons.push( button_cancel )
                buttons.push( button_save )
            } else {
                return
            }

            let settings = {
                width: 6,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: [
                                    {
                                        name: 'naziv',
                                        type: 'input',
                                        title: 'Naziv',
                                        value: vm.data['naziv'] || '',
                                        width: 12,
                                        error: vm.errors['naziv'] || '',
                                        note: '',
                                        explanation: '',
                                        placeholder: '',
                                        required: !vm.disabled,
                                        readonly: vm.disabled,
                                        disabled: vm.disabled,
                                        config: {}
                                    },
                                    {
                                        name: 'dugovna_strana',
                                        type: 'select',
                                        title: 'Dugovna strana',
                                        value: {
                                            key: vm.data['dugovna_strana'] ? vm.data['dugovna_strana'] : '0',
                                            value: vm.data['dugovna_strana'] ? 'DA' : 'NE'
                                        },
                                        width: 12,
                                        error: vm.errors['dugovna_strana'] ? vm.errors['dugovna_strana'] : '',
                                        note: '',
                                        explanation: '',
                                        placeholder: '',
                                        required: !vm.disabled,
                                        readonly: vm.disabled,
                                        disabled: vm.disabled,
                                        config: {
                                            options: [
                                                {
                                                    key: '0',
                                                    value: 'NE'
                                                },
                                                {
                                                    key: '1',
                                                    value: 'DA'
                                                },
                                            ],
                                            title_width: 4,
                                            input_width: 8
                                        }
                                    },
                                    {
                                        name: 'potrazna_strana',
                                        type: 'select',
                                        title: 'Potražna strana',
                                        value: {
                                            key: vm.data['potrazna_strana'] ? vm.data['potrazna_strana'] : '0',
                                            value: vm.data['potrazna_strana'] ? 'DA' : 'NE'
                                        },
                                        width: 12,
                                        error: vm.errors['potrazna_strana'] ? vm.errors['potrazna_strana'] : '',
                                        note: '',
                                        explanation: '',
                                        placeholder: '',
                                        required: !vm.disabled,
                                        readonly: vm.disabled,
                                        disabled: vm.disabled,
                                        config: {
                                            options: [
                                                {
                                                    key: '0',
                                                    value: 'NE'
                                                },
                                                {
                                                    key: '1',
                                                    value: 'DA'
                                                },
                                            ],
                                            title_width: 4,
                                            input_width: 8
                                        }
                                    }
                                ],
                            }
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings

        },

        /**
         *  on input return data
         */
        // onInput( name, value ){
        //     console.log( 'AdministratorForma input -> ' + name + ':' , value )
        // },

        /**
         *  on button click
         */
        onFormButtonClick( id, action, config, data  ){

            if( id === 'save' || id === 'update' ){
                if( data.success){
                    if( id == 'save' )
                        toastr.success( 'Uspješno spremljeno!' )
                    else if ( id == 'update' )
                        toastr.success( 'Uspješno ažurirano!' )
                    this.$router.push({ path: this.route } )
                } else {
                    this.errors = data.messages
                    this.init()
                }
            } else if( id == 'delete' ){
                toastr.error( 'Uspješno obrisano!' )
                this.$router.push({ path: this.route } )
            }

        },

    },
    mounted(){
        this.init()
    }
};
</script>

<style scoped>

</style>
