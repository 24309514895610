<template>
    <div>
        <breadcrumbs
            :title="'Administratori'"
            :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Administrator {{ ime }} {{ prezime }}</h5>
            </div>
            <div class="ibox-content">
                <administrator-forma
                    :disabled="true"
                    :id="id"
                    :modal="false"
                    :action="'show'"
                    @returnData="setData"
                >
                </administrator-forma>
            </div>
        </div>
    </div>

</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import AdministratorForma from "@/views/pages/Administratori/AdministratorForma";

export default {
    name: "AdministratorDetails",
    components:{ AdministratorForma, Breadcrumbs },
    data(){
        return {
            id: this.$route.params.admin_id,
            breadcrumbs: [],
            ime: '',
            prezime: '',
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/administratori',
                    name: 'Administratori'
                },
                {
                    path: ( '/administratori/' + this.id ),
                    name: ( 'Administrator: ' + this.ime + ' ' + this.prezime )
                }
            ]
        },
        setData( data ){
            this.ime = data.ime ? data.ime : ''
            this.prezime = data.prezime ? data.prezime : ''
            this.setBreadcrumbs()
        }
    },
}
</script>

<style scoped>

</style>
