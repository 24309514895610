<template>
    <div>
        <osobe-navigation
            :id="osoba_id"
            :breadcrumbs="breadcrumbs"
        >
        </osobe-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Nova dodatna aktivnost</h5>
            </div>
            <div class="ibox-content">
                <osoba-dodatna-aktivnost-forma
                    :disabled="false"
                    :id="null"
                    :modal="false"
                    :action="'create'"
                >
                </osoba-dodatna-aktivnost-forma>
            </div>
        </div>
    </div>
</template>

<script>
import OsobeNavigation from "../OsobeNavigation";
import OsobaDodatnaAktivnostForma from "./OsobaDodatnaAktivnostForma";
export default {
    name: "OsobaDodatnaAktivnostCreate",
    components: { OsobaDodatnaAktivnostForma, OsobeNavigation },
    data(){
        return {
            breadcrumbs: [],
            osoba_id: this.$route.params.osoba_id
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/osobe/' + this.osoba_id + '/dodatne-aktivnosti',
                    name: 'Dodatne aktivnosti'
                },
                {
                    path: '/osobe/' + this.osoba_id + '/dodatne-aktivnosti/create',
                    name: 'Nova dodatna aktivnost'
                }
            ]
        }
    },
    mounted() {
        this.setBreadcrumbs()
    }
};
</script>

<style scoped>

</style>
