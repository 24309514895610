<template>
    <div>
        <breadcrumbs
            :title="'Dodatne aktivnosti'"
            :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Nova dodatna aktivnost</h5>
            </div>
            <div class="ibox-content">
                <dodatna-aktivnost-forma
                    :disabled="false"
                    :id="null"
                    :modal="false"
                    :action="'create'"
                ></dodatna-aktivnost-forma>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import DodatnaAktivnostForma from "./DodatnaAktivnostForma";
export default {
    name: "DodatnaAktivnostCreate",
    components:{
        Breadcrumbs,
        DodatnaAktivnostForma
    },
    data(){
        return {
            breadcrumbs : []
        }
    },
    methods:{
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/postavke/dodatne-aktivnosti',
                    name: 'Dodatne aktivnosti'
                },
                {
                    path: '/postavke/dodatne-aktivnosti/create',
                    name: 'Nova dodatna aktivnost'
                }
            ]
        }
    }
};
</script>

<style scoped>

</style>