<template>
    <div class="row border-bottom">
        <nav class="navbar navbar-static-top" role="navigation" style="margin-bottom: 0">
            <div class="navbar-header">
                <a class="navbar-minimalize minimalize-styl-2 btn btn-primary " href="#" @click.prevent="show_left_menu"><i class="fa fa-bars"></i> </a>
            </div>
            <ul class="nav navbar-top-links navbar-left">
                <li style="">

<!--                    <a class="text-success text-lg-center" href="/doc/doc.pdf" target="_blank">-->
<!--                        <i class="fa fa-folder">-->
<!--                            Dokumentacija-->
<!--                        </i>-->
<!--                    </a>-->
                </li>
            </ul>
            <ul class="nav navbar-top-links navbar-right">
                <li style="">
                    <span class="m-r-sm text-muted welcome-message">{{ name }} </span>
                </li>
                <li>
                    <a href="#" @click.prevent="logout">
                        Odjavi se <i class="fa fa-sign-out-alt"></i>
                    </a>
                </li>
            </ul>

        </nav>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import toastr from "toastr";
export default {
    name: "NavigationTop",
    props:[],
    data(){
        return {
            name: ''
        }
    },
    computed: {
        ...mapGetters("auth", ["user"])
    },
    methods:{

        ...mapActions("auth", ["getUserData"]),

        show_left_menu(){
            this.$emit( 'show_left_menu' );
        },

        logout() {
            let vm = this;
            axios.post(process.env.VUE_APP_API_URL + "logout", {
                email: vm.email,
                password: vm.password
            })
                    .then(response => {
                        if( response.data.success ){
                            vm.$store.commit("auth/setUserData", null) ;
                            localStorage.setItem("access_token", null );
                            vm.$router.push("logout")
                        } else {
                            toastr.error('Došlo je do greške')
                        }
                    })
                    .catch( function ( error ) {
                        if(error instanceof Error) {
                            console.log( 'Error: ' . error);
                        } else {
                            console.log( 'Unexpected response: ' . error);
                        }
                    } )
        }
    },
    mounted() {
        if (localStorage.getItem("access_token")) {
            this.getUserData();
            if ( this.user )
                this.name = this.user.username
        }
    }

}
</script>

<style scoped>

</style>