<template>
    <div class="col-lg-4" >

        <div class="vld-parent">
            <loading :active.sync="open_loader"
                     :can-cancel="false"
                     :is-full-page="true">

            </loading>
        </div>

        <button class="btn btn-light float-right" @click="exportExcel">Excel</button>
        <button class="btn btn-light float-right" @click="exportWord">Word</button>
        <button class="btn btn-light float-right" @click="exportCsv">CSV</button>
        <div class="float-right" style="margin-right: 10px"><strong>Export:</strong></div>
    </div>
</template>

<script>

import axios from "axios"
import * as DOCX from "docx";
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: "Export",
    components:{Loading},
    props: {
        url: {
            type: String,
            default: '/'
        },
        order_by: {
            type: String,
            required:true
        },
        order: {
            type: String,
            required:true,
            default:'desc',
            validator: function( order ){
                return ['asc', 'desc'].includes( order )
            }
        },
        title: {
            type: String,
            default: ''
        },
        filters:{
            type: Object,
            validate: function( props ){
                for( let i in props ){
                    if(
                            typeof props[i].id !== 'string'
                            && typeof props[i].value !== 'string'
                    )
                        return false
                }
                return true
            }
        },
        headers:{
            type: Array,
            validate: function( props ){
                for( let i in props ){
                    if(
                            typeof props[i].id !== 'string'
                            && typeof props[i].title !== 'string'
                        )
                        return false
                }
                return true
            }
        }

    },
    data(){
        return {
            open_loader: false,
        }
    },
    methods:{
        exportExcel(){
            let vm = this;
            vm.open_loader = true

            let params = {
                order_by: vm.order_by,
                order: vm.order,
                page: 1,
                per_page: -1,
                filters: vm.filters,
                optionsa: vm.optionsa,
            }

            let data = [];
            axios.get( vm.url, {
                params: params
            } )
                .then( function( response ) {
                    data = response.data.data;

                    var today = new Date();
                    var dd = String(today.getDate()).padStart(2, '0');
                    var mm = String(today.getMonth() + 1).padStart(2, '0')
                    var yyyy = today.getFullYear();

                    /* table title and date */
                    var export_data  = [
                        [ vm.title + ', ' + dd + '.' + mm + '.' + yyyy + '.' ]
                    ];

                    let tmp = [];
                    // append headers title
                    tmp.push( '#')
                    vm.headers.forEach( function( h ){
                        if( h.visible && ( typeof h.type === 'function' || h.type === 'text' ) )
                            tmp.push( h.title )
                    } )
                    export_data.push( tmp )



                    data.forEach( function( item, index ) {

                        if( !export_data[index + 2] )
                            export_data[index + 2] = []
                        export_data[ index + 2].push( index )
                    } )

                    vm.headers.forEach( function ( h ) {
                        let i = 2
                        if( h.visible  ){
                            data.forEach( function( item ) {

                                if( typeof h.type === 'function' ){
                                    let ret = h.type.call( this, item, h )

                                    if( typeof ret === 'string' || typeof ret === 'number' ){
                                        if( !export_data[i] )
                                            export_data[i] = []
                                        export_data[i].push( ret )
                                    }
                                } else if ( h.type === 'text' ){
                                    if( !export_data[i] )
                                        export_data[i] = []
                                    export_data[i].push( item[h.id] )
                                }
                                i++
                            } )
                        }
                    } )


                    // mwrge title column
                    let merge_c = { s: {r:0, c:0}, e: {r:0, c: Object.keys( vm.headers[0] ).length + 1 } }

                    /* generate worksheet */
                    var ws = window.XLSX.utils.aoa_to_sheet(export_data)

                    /* add merges */
                    if(!ws['!merges']) ws['!merges'] = []
                    ws['!merges'].push(merge_c)

                    /* generate workbook */
                    var wb = window.XLSX.utils.book_new()
                    window.XLSX.utils.book_append_sheet(wb, ws, "sheet1")

                    /* generate file and download */
                    const wbout_c = window.XLSX.write(wb, { type: "array", bookType: "xlsx" })
                    let blob = new Blob([wbout_c], { type: "application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," })
                    var a = document.createElement("a")
                    a.href = URL.createObjectURL(blob)
                    a.download = vm.title ? (vm.title + '.xlsx') : ( today + '.xlsx' )
                    a.click()
                    a.remove()

                    vm.open_loader = false

                } ).catch( function ( response ) {
                    vm.open_loader = false
                    console.log( response )
                } )

        },

        exportWord(){
            let vm = this
            vm.open_loader = true

            let params = {
                order_by: vm.order_by,
                order: vm.order,
                page: 1,
                per_page: -1,
                filters: vm.filters,
                optionsa: vm.optionsa,
            }

            let data = [];
            axios.get( vm.url, {
                params: params
            } )
                .then( function( response ) {
                    data = response.data.data;

                    var today = new Date();
                    var dd = String(today.getDate()).padStart(2, '0');
                    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                    var yyyy = today.getFullYear();


                    // new docx
                    const doc = new window.DOCX.Document({
                        title: vm.title ? (vm.title + '.docx') : ( today + '.docx' )
                    })

                    // title paragraph
                    let title =  new window.DOCX.Paragraph( {
                        text: vm.title + ', ' + dd + '.' + mm + '.' + yyyy + '.',
                        heading: window.DOCX.HeadingLevel.TITLE,
                        alignment: window.DOCX.AlignmentType.CENTER
                    } )

                    // array of table rows
                    let rows = [];

                    // array of table cells
                    let cells = [];

                    // title cell for count
                    let cell = new window.DOCX.TableCell({
                        children: [ new window.DOCX.Paragraph( '#' )]
                    })
                    cells.push( cell )

                    vm.headers.forEach( function( h ){
                        // add other title cells
                        if( h.visible && ( typeof h.type === 'function' || h.type === 'text' ) ){
                            let tmp =  h.title
                            cells.push( new window.DOCX.TableCell({
                                children: [ new window.DOCX.Paragraph( { text: String( tmp ) } )]
                            }) )
                        }

                    } )

                    // push title cell into row
                    rows.push( new window.DOCX.TableRow( {
                        children: cells
                    } ) )


                    var export_data = [];

                    vm.headers.forEach( function ( h ) {
                        let i = 2
                        if( h.visible  ){
                            data.forEach( function( item ) {

                                if( typeof h.type === 'function' ){
                                    let ret = h.type.call( this, item, h )

                                    if( typeof ret === 'string' || typeof ret === 'number' ){
                                        if( !export_data[i] )
                                            export_data[i] = []
                                        export_data[i].push( ret )
                                    }
                                } else if ( h.type === 'text' ){
                                    if( !export_data[i] )
                                        export_data[i] = []
                                    export_data[i].push( item[h.id] )
                                }
                                i++
                            } )
                        }
                    } )

                    let i = 1
                    export_data.forEach( function( item ) {
                        cells = [];
                        cells.push( new window.DOCX.TableCell({
                            children: [ new window.DOCX.Paragraph({ text: String(i) })]
                        }) )
                        i++
                        item.forEach( function( row ) {
                            cells.push(
                                    new window.DOCX.TableCell(
                                            {
                                                children: [
                                                        new window.DOCX.Paragraph(
                                                                {
                                                                    text:String( row ),
                                                                    alignment: window.DOCX.AlignmentType.CENTER
                                                                }
                                                        )
                                                ]
                                            }
                                    )
                            )
                        } )
                        rows.push(
                                new window.DOCX.TableRow({children: cells} )
                        )
                    } )

                    // pus rows into table
                    const table = new window.DOCX.Table({
                        rows: rows,
                        cantSplit: true,
                    });

                    // push table to document
                    doc.addSection({
                        children: [ title, table ]
                    })


                    DOCX.Packer.toBlob(doc).then((blob) => {
                        let a = document.createElement("a")
                        a.href = URL.createObjectURL(blob)
                        a.download = vm.title ? (vm.title + '.docx') : ( today + '.docx' )
                        a.click()
                        a.remove()
                    });
                    vm.open_loader = false
                } ).catch( function ( response ) {
                    vm.open_loader = false
                    console.log( response )
                } )


        },

        exportCsv(){
            let vm = this;
            vm.open_loader = true

            let params = {
                order_by: vm.order_by,
                order: vm.order,
                page: 1,
                per_page: -1,
                filters: vm.filters,
                optionsa: vm.optionsa,
            }

            let data = [];
            axios.get( vm.url, {
                params: params
            } )
                .then( function( response ) {
                    data = response.data.data;

                    let export_data = []

                    vm.headers.forEach( function ( h ) {
                        let i = 2
                        if( h.visible  ){
                            data.forEach( function( item ) {

                                if( typeof h.type === 'function' ){
                                    let ret = h.type.call( this, item, h )

                                    if( typeof ret === 'string' || typeof ret === 'number' ){
                                        if( !export_data[i] )
                                            export_data[i] = []
                                        export_data[i].push( ret )
                                    }

                                } else if ( h.type === 'text' ){
                                    if( !export_data[i] )
                                        export_data[i] = []
                                    export_data[i].push( item[h.id] )
                                }
                                i++
                            } )
                        }
                    } )

                    let csv = '';
                    export_data.forEach( function( item ) {
                        let row = ''

                        item.forEach( function ( r ) {
                            row += '"' + r + '",'
                        } )
                        row.slice(0, row.length - 1)
                        csv += row + '\r\n';
                    })

                    /* generate file and download */
                    let blob = new Blob([csv], { type: 'text/csv' })
                    let a = document.createElement("a")
                    a.href = URL.createObjectURL(blob)
                    a.download = vm.title ? (vm.title + '.csv') : ( 'csv.csv' )
                    a.click()
                    a.remove()
                    vm.open_loader = false
                } ).catch( function ( response ) {
                    vm.open_loader = false
                    console.log( response )
                } )
        }
    }
}
</script>

<style scoped>

</style>