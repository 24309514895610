<template>
    <div>
        <forms
            :settings="settings"
            @onFormButtonClick="onFormButtonClick"
            @onInput="onInput"
        >
        </forms>
    </div>
</template>

<script>
import Forms from "../../../../components/form/Forms";
import axios from "axios";
import toastr from "toastr";
import moment from "moment";
export default {
    name: "SkupPrometForma",
    components: { Forms },
    props:{
        disabled:{
            type: Boolean,
            default: false
        },
        id:{
            default: null
        },
        modal:{
            type: Boolean,
            default: false
        },
        action: {
            type: String
        }
    },
    data(){
        return {
            settings: {},
            errors: {},
            route: '/skupovi/' + this.$route.params.skup_id + '/promet',
            url: 'promet',
            data: {},
            skup_id: this.$route.params.skup_id,


            vrste_prometa_select: [],
            osobe_select: [],
        }
    },

    methods: {



        async fetctVrstePrometa(){
            let vm = this
            const response = await axios.get(process.env.VUE_APP_API_URL + 'vrsta-prometa' )
            return response.data.data.map( function( item ) {
                vm.vrste_prometa_select.push(
                    {
                        key: item.id.toString(),
                        value: item.naziv
                    }
                )
            } )
        },

        getVrstaPrometa(id) {
            return this.vrste_prometa_select.find( item => item.key === id )
        },

        async fetchOsobe(){
            let vm = this
            const response = await axios.get(process.env.VUE_APP_API_URL + 'osoba2' )
            return response.data.data.map( function( item ) {
                vm.osobe_select.push(
                    {
                        key: item.id.toString(),
                        value: item.ime + ' ' + item.prezime + ' (' + item.oib + ')'
                    }
                )
            } )
        },

        getOsoba( id ){
            return this.osobe_select.find( item => item.key === id )
        },



        async init(){

            let vm = this

            vm.open_loader = true

            await vm.fetctVrstePrometa()
            await vm.fetchOsobe()

            if ( vm.action === 'edit' || vm.action === 'show' ){
                axios.get( process.env.VUE_APP_API_URL + vm.url + '/' + vm.id )
                    .then( async function ( response ) {

                        vm.data.osoba_id = response.data.osoba_id ? response.data.osoba_id.toString() : ''
                        vm.data.vrsta_prometa_id = response.data.vrsta_prometa_id ? response.data.vrsta_prometa_id.toString() : ''
                        vm.data.duguje = response.data.duguje
                        vm.data.potrazuje = response.data.potrazuje
                        vm.data.datum_zaduzenja = response.data.datum_zaduzenja
                        vm.data.opis = response.data.opis
                        vm.data.oznaka = response.data.oznaka
                        vm.data.napomena = response.data.napomena
                        vm.data.datum_uplate = response.data.datum_uplate

                        vm.$emit( 'returnData', response.data )
                        vm.setupForm()
                        vm.open_loader = false
                    } )
                    .catch( function ( error ) {
                        vm.open_loader = false
                        if(error instanceof Error) {
                            console.log( 'Error: ' . error);
                        } else {
                            console.log( 'Unexpected response: ' . error);
                        }
                    } )
            } else if ( vm.action === 'create' ) {
                vm.data = {}
                vm.setupForm()
                vm.open_loader = false
            } else {
                vm.open_loader = false
            }
        },

        setupForm(){

            let vm = this;

            vm.buttons = []

            let button_cancel = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
                action: 'redirect', // request
                config: {
                    url: vm.route + ( vm.action === 'edit' ? ( '/' + vm.id) : '' )
                }
            }

            let button_delete = {
                id: 'delete',
                title: 'Obriši',
                type: 'danger',
                action: 'request',
                config: {
                    method: 'DELETE',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let button_edit = {
                id: 'edit',
                title: 'Uredi',
                type: 'success',
                action: 'redirect',
                config: {
                    url: vm.route +  '/' + vm.id +'/edit'
                }
            }

            let button_save = {
                id: 'save',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + vm.url
                }
            }

            let button_update = {
                id: 'update',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'PUT',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let buttons = []


            if( vm.action === 'edit' ){
                buttons.push( button_cancel)
                buttons.push( button_update)
            } else if( vm.action === 'show' ) {
                buttons.push( button_cancel )
                buttons.push( button_delete )
                buttons.push( button_edit )
            }
            else if ( vm.action === 'create' ) {
                buttons.push( button_cancel )
                buttons.push( button_save )
            } else {
                return
            }

            let inputs = []


            let skup_id_input = {
                name: 'skup_id',
                type: 'hidden',
                value: vm.skup_id,
                disabled: vm.disabled,
                config: {
                    hidden:true
                }
            }
            inputs.push( skup_id_input )

            let osoba_id_input = {
                name: 'osoba_id',
                type: 'select',
                title: 'Osoba',
                value: vm.data.osoba_id ? vm.getOsoba( vm.data.osoba_id ) : '',
                width: 12,
                error: vm.errors['osoba_id'] ? vm.errors['osoba_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.osobe_select,
                }
            }
            inputs.push( osoba_id_input )

            let vrste_prometa_id_input = {
                name: 'vrsta_prometa_id',
                type: 'select',
                title: 'Vrsta prometa',
                value: vm.getVrstaPrometa( vm.data.vrsta_prometa_id ),
                width: 12,
                error: vm.errors['vrsta_prometa_id'] ? vm.errors['vrsta_prometa_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.vrste_prometa_select,
                    multiple: false
                }
            }
            inputs.push( vrste_prometa_id_input )

            let duguje_input = {
                name: 'duguje',
                type: 'input',
                title: 'Duguje',
                value: vm.data.duguje,
                width: 12,
                error: vm.errors['duguje'] ? vm.errors['duguje'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }
            inputs.push( duguje_input )

            let potrazuje_input = {
                name: 'potrazuje',
                type: 'input',
                title: 'Potražuje',
                value: vm.data.potrazuje,
                width: 12,
                error: vm.errors['potrazuje'] ? vm.errors['potrazuje'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }
            inputs.push( potrazuje_input )

            let datum_zaduzenja_input = {
                name: 'datum_zaduzenja',
                type: 'datepicker',
                title: 'Datum zaduženja',
                value:  vm.data.datum_zaduzenja || moment( new Date(), 'DD.MM.YYYY.').format('DD.MM.YYYY.'),
                width: 12,
                error: vm.errors['datum_zaduzenja'] ? vm.errors['datum_zaduzenja']: '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }
            inputs.push( datum_zaduzenja_input )

            let opis_input = {
                name: 'opis',
                type: 'input',
                title: 'Opis',
                value: vm.data.opis,
                width: 12,
                error: vm.errors['opis'] ? vm.errors['opis'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }
            inputs.push( opis_input )

            let oznaka_input = {
                name: 'oznaka',
                type: 'input',
                title: 'Oznaka',
                value: vm.data.oznaka,
                width: 12,
                error: vm.errors['oznaka'] ? vm.errors['oznaka'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }
            inputs.push( oznaka_input )

            let napomena_input = {
                name: 'napomena',
                type: 'input',
                title: 'Napomena',
                value: vm.data.napomena,
                width: 12,
                error: vm.errors['napomena'] ? vm.errors['napomena'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }
            inputs.push( napomena_input )

            let datum_uplate_input = {
                name: 'datum_uplate',
                type: 'datepicker',
                title: 'Datum uplate',
                value:  vm.data.datum_uplate || moment( new Date(), 'DD.MM.YYYY.').format('DD.MM.YYYY.'),
                width: 12,
                error: vm.errors['datum_uplate'] ? vm.errors['datum_uplate']: '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }
            inputs.push( datum_uplate_input )


            let main_section = {
                name: ' ',
                width: 12,
                inputs: inputs
            }

            let sections = []

            sections.push( main_section )



            let settings = {
                width: 6,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: sections
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },

        /**
         *  on input return data
         */
        async onInput( name, value ){

            if( this.data[name] !== value ){
                this.data[name] = value
            }
        },

        /**
         *  on button click
         */
        onFormButtonClick( id, action, config, data  ){

            if( id === 'save' || id === 'update' ){
                if( data.success){
                    if( id == 'save' )
                        toastr.success( 'Uspješno spremljeno!' )
                    else if ( id == 'update' )
                        toastr.success( 'Uspješno ažurirano!' )
                    this.$router.push({ path: this.route + '/' + data.data.id } )
                } else {
                    this.errors = data.messages
                    this.setupForm()
                }
            } else if( id == 'delete' ){
                toastr.error( 'Uspješno obrisano!' )
                this.$router.push({ path: this.route } )
            }

        },
    },

    mounted() {
        this.init()
    }
}
</script>

<style scoped>

</style>
