<template>
    <div>
        <breadcrumbs
            :title="'Vrste prometa'"
            :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Nova vrsta prometa</h5>
            </div>
            <div class="ibox-content">
                <vrsta-prometa-forma
                    :disabled="false"
                    :id="null"
                    :modal="false"
                    :action="'create'"
                ></vrsta-prometa-forma>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import VrstaPrometaForma from "./VrstaPrometaForma";
export default {
    name: "VrstaPrometaCreate",
    components: {
        VrstaPrometaForma,
        Breadcrumbs
    },
    data(){
        return {
            breadcrumbs: []
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/postavke/vrste-prometa',
                    name: 'Vrste prometa'
                },
                {
                    path: '/postavke/vrste-prometa/create',
                    name: 'Nova vrsta prometa'
                }
            ]
        }
    },
    mounted(){
        this.setBreadcrumbs()
    }
};
</script>

<style scoped>

</style>
