<template>
    <div>
        <osobe-navigation
            :id="osoba_id"
            :breadcrumbs="breadcrumbs"
        >
        </osobe-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Dodatne aktivnosti</h5><br>
                <button
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click.prevent="create"
                >
                    Novi unos
                </button>
            </div>
            <div class="ibox-content">
                <datatables
                    v-if="show_datatable"
                    :url="datatables_url"
                    :settings="datatables.settings"
                    :title="datatables.title"
                    @rowClick="rowClick"
                >
                </datatables>
            </div>
        </div>
    </div>
</template>

<script>
import Datatables from "../../../../components/datatable/Datatables";
import OsobeNavigation from "../OsobeNavigation";
export default {
    name: "OsobaDodatneAktivnosti",
    components: { OsobeNavigation, Datatables },
    data(){
        return {
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL +  'osoba-dodatna-aktivnost',
            datatables: {},
            breadcrumbs: [],
            osoba_id: this.$route.params.osoba_id
        }
    },
    methods:{
        rowClick( row ){
            this.$router.push( { path: '/osobe/' + this.osoba_id + '/dodatne-aktivnosti/' + row.id } )
        },

        create(){
            this.$router.push( { path: '/osobe/' + this.osoba_id + '/dodatne-aktivnosti/create' } )
        },

        setDatatable() {
            let vm = this;
            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'osoba_id',
                            'visible': false,
                            'filter':{
                                'type': 'none',
                                'value': vm.osoba_id
                            },
                        },
                        {
                            'id': 'datum_od',
                            'title': 'Od',
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'osoba-dodatna-aktivnost',
                                'visible' : true
                            },
                            'filter': {
                                'type': 'date',
                            }
                        },
                        {
                            'id': 'datum_do',
                            'title': 'Do',
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'osoba-dodatna-aktivnost',
                                'visible' : true
                            },
                            'filter': {
                                'type': 'date',
                            }
                        },
                        {
                            'id': 'dodatna_aktivnost_naziv',
                            'title': 'Naziv',
                            'type' : function( row ){
                                return row.dodatna_aktivnost ? row.dodatna_aktivnost.naziv : ''
                            },
                            'column_visibility_group': {
                                'id': 'osoba-dodatna-aktivnost',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'opis',
                            'title': 'Opis',
                            'type' : function( row ){
                                return row.opis
                            },
                            'column_visibility_group': {
                                'id': 'osoba-dodatna-aktivnost',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'sati',
                            'title': 'Sati',
                            'type' : function( row ){
                                return row.sati
                            },
                            'column_visibility_group': {
                                'id': 'osoba-dodatna-aktivnost',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'sati_regulativa',
                            'title': 'Sati regulativa',
                            'type' : function( row ){
                                return row.sati_regulativa
                            },
                            'column_visibility_group': {
                                'id': 'osoba-dodatna-aktivnost',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'podrucje_teme_naziv',
                            'title': 'Područje teme',
                            'type' : function( row ){
                                return row.podrucje_teme ? row.podrucje_teme.naziv : ''
                            },
                            'column_visibility_group': {
                                'id': 'osoba-dodatna-aktivnost',
                                'visible' : true
                            }
                        },

                    ],
                    column_visibility_groups:{
                        'osoba-dodatna-aktivnost' : 'Dodatna aktivnost'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Dodatna aktivnost',
            }
            vm.show_datatable = true;
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/osobe/' + this.osoba_id + '/dodatne-aktivnosti',
                    name: 'Dodatne aktivnosti'
                }
            ]
        }
    },
    mounted() {
        this.setDatatable()
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>
