<template>
    <router-view></router-view>
</template>

<script>
export default {
  name: "SkupBase"
};
</script>

<style scoped>

</style>
