<template>
    <div>
        <skupovi-navigation
            :id="skup_id"
            :breadcrumbs="breadcrumbs"
        >
        </skupovi-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Promet</h5><br>
                <button
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click.prevent="create"
                >
                    Novi unos
                </button>
            </div>
            <div class="ibox-content">
                <datatables
                    v-if="show_datatable"
                    :url="datatables_url"
                    :settings="datatables.settings"
                    :title="datatables.title"
                    @rowClick="rowClick"
                >
                </datatables>
            </div>
        </div>
    </div>
</template>

<script>
import Datatables from "../../../../components/datatable/Datatables";
import SkupoviNavigation from "../SkupNavigation";
import axios from "axios";
export default {
    name: "SkupStavke",
    components: { SkupoviNavigation, Datatables },
    data(){
        return {
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL +  'promet',
            datatables: {},
            breadcrumbs: [],
            skup_id: this.$route.params.skup_id
        }
    },
    methods:{
        rowClick( row ){
            this.$router.push( { path: '/skupovi/' + this.skup_id + '/promet/' + row.id } )
        },

        create(){
            this.$router.push( { path: '/skupovi/' + this.skup_id + '/promet/create' } )
        },

        setDatatable() {
            let vm = this;
            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'skup_id',
                            'visible': false,
                            'filter':{
                                'type': 'none',
                                'value': vm.skup_id
                            },
                        },
                        {
                            'id': 'ime',
                            'title': 'Ime',
                            'type' : function( row ){
                                return row.osoba ? row.osoba.ime : ''
                            },
                            'column_visibility_group': {
                                'id': 'promet',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'prezime',
                            'title': 'Prezime',
                            'type' : function( row ){
                                return row.osoba ? row.osoba.prezime : ''
                            },
                            'column_visibility_group': {
                                'id': 'promet',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'oib',
                            'title': 'OIB',
                            'type' : function( row ){
                                return row.osoba ? row.osoba.oib : ''
                            },
                            'column_visibility_group': {
                                'id': 'promet',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'vrsta_prometa_id',
                            'title': 'Vrsta prometa',
                            'type' : function( row ){
                                return row.vrsta_prometa ? row.vrsta_perometa.naziv : ''
                            },
                            'filter': {
                                'type': 'select',
                                'search': function ( ){
                                    let component = this;

                                    axios.get( process.env.VUE_APP_API_URL +  'vrsta-prometa', {
                                        params: {
                                            filters: {}
                                        }
                                    } )
                                        .then( function ( response ) {
                                            let tmp = [];

                                            response.data.data.forEach( function ( item ) {
                                                tmp.push({
                                                    key: item.id,
                                                    value: item.naziv
                                                } );
                                            } );
                                            component.$data.tmp = tmp;
                                        } )
                                        .catch( function ( error ) {
                                            if(error instanceof Error) {
                                                console.log( 'Error: ' . error);
                                            } else {
                                                console.log( 'Unexpected response: ' . error);
                                            }
                                        } )
                                },
                            },
                            'column_visibility_group': {
                                'id': 'promet',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'duguje',
                            'title': 'Duguje',
                            'type' : function( row ){
                                return row.duguje
                            },
                            'column_visibility_group': {
                                'id': 'promet',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'potrazuje',
                            'title': 'Potražuje',
                            'type' : function( row ){
                                return row.potrazuje
                            },
                            'column_visibility_group': {
                                'id': 'promet',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'datum_zaduzenja',
                            'title': 'Datum zaduženja',
                            'type' : function( row ){
                                return row.datum_zaduzenja
                            },
                            'column_visibility_group': {
                                'id': 'promet',
                                'visible' : true
                            },
                            'filter': {
                                'type': 'date',
                            }
                        },
                        {
                            'id': 'opis',
                            'title': 'Opis',
                            'searchable': false,
                            'type' : function( row ){
                                return row.opis
                            },
                            'column_visibility_group': {
                                'id': 'promet',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'datum_uplate',
                            'title': 'Datum uplate',
                            'type' : function( row ){
                                return row.datum_uplate
                            },
                            'column_visibility_group': {
                                'id': 'promet',
                                'visible' : true
                            },
                            'filter': {
                                'type': 'date',
                            }
                        }
                    ],
                    column_visibility_groups:{
                        'promet' : 'Promet'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Promet',
            }
            vm.show_datatable = true;
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/skupovi/' + this.skup_id + '/promet',
                    name: 'Promet'
                }
            ]
        }
    },
    mounted() {
        this.setDatatable()
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>
