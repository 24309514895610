<template>
    <div>
        <osobe-navigation
            :id="osoba_id"
            :breadcrumbs="breadcrumbs"
        >
        </osobe-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Tvrtka: {{ naziv }}</h5>
            </div>
            <div class="ibox-content">
                <osoba-tvrtka-forma
                    :disabled="false"
                    :id="id"
                    :modal="false"
                    :action="'edit'"
                    @returnData="setData"
                >
                </osoba-tvrtka-forma>
            </div>
        </div>
    </div>
</template>

<script>
import OsobeNavigation from "../OsobeNavigation";
import OsobaTvrtkaForma from "./OsobaTvrtkaForma";
export default {
    name: "OsobaTvrtkaEdit",
    components: { OsobaTvrtkaForma, OsobeNavigation },
    data(){
        return {
            breadcrumbs: [],
            id:this.$route.params.osoba_tvrtka_id,
            osoba_id: this.$route.params.osoba_id,
            naziv: ''
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/osobe/' + this.osoba_id + '/tvrtke',
                    name: 'Tvrtke'
                },
                {
                    path: '/osobe/' + this.osoba_id + '/tvrtke/' + this.id,
                    name: 'Tvrtka: ' + this.naziv
                },
                {
                    path: '/osobe/' + this.osoba_id + '/tvrtke/' + this.id + '/edit',
                    name: 'Uredi tvrtku: ' + this.naziv
                }
            ]
        },
        setData( data ){
            this.naziv = data.tvrtka.naziv
            this.setBreadcrumbs()
        }
    }
};
</script>

<style scoped>

</style>
