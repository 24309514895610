<template>
    <div>

        <breadcrumbs
            :title="'Skupovi'"
            :breadcrumbs="allBreadcrumbs"
        >
        </breadcrumbs>

        <nav class="navbar navbar-expand-lg navbar-light" style="background-color: #2F4050;">
            <a
                :class="{
                            'navbar-brand btn btn-light btn-xl': true,
                            'active': active_route === route
                        }"
                @click="open(route)"
            >
                <strong>Skup</strong>
            </a>

            <div v-if="skup" class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item">
                        <a
                            role="button"
                            :class="{
                                    'nav-link btn btn-light': true,
                                    'active': active_route === route + '/teme'
                                }"
                            @click.prevent="open( route + '/teme' )"
                        >
                            <strong>Teme</strong>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a
                            role="button"
                            :class="{
                                    'nav-link btn btn-light': true,
                                    'active': active_route === route + '/teme2'
                                }"
                            @click.prevent="open( route + '/teme2' )"
                        >
                            <strong>Podteme</strong>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a
                            role="button"
                            :class="{
                                    'nav-link btn btn-light': true,
                                    'active': active_route === route + '/suorganizatori'
                                }"
                            @click.prevent="open( route + '/suorganizatori' )"
                        >
                            <strong>Suorganizatori</strong>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a
                            role="button"
                            :class="{
                                    'nav-link btn btn-light': true,
                                    'active': active_route === route + '/prijava'
                                }"
                            @click.prevent="open( route + '/prijave' )"
                        >
                            <strong>Prijave</strong>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a
                            role="button"
                            :class="{
                                    'nav-link btn btn-light': true,
                                    'active': active_route === route + '/stavke'
                                }"
                            @click.prevent="open( route + '/stavke' )"
                        >
                            <strong>Stavke</strong>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a
                            role="button"
                            :class="{
                                    'nav-link btn btn-light': true,
                                    'active': active_route === route + '/promet'
                                }"
                            @click.prevent="open( route + '/promet' )"
                        >
                            <strong>Promet</strong>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a
                            role="button"
                            :class="{
                                    'nav-link btn btn-light': true,
                                    'active': active_route === route + '/emailing'
                                }"
                            @click.prevent="open( route + '/emailing' )"
                        >
                            <strong>Emailing</strong>
                        </a>
                    </li>

                </ul>
            </div>
        </nav>


    </div>
</template>

<script>

import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import axios from "axios";
export default {
    name: "SkupoviNavigation",
    components: {Breadcrumbs},
    props:[
        'id',
        'breadcrumbs'
    ],
    data(){
        return {
            skup : null,
            naziv: '',
            route: '/skupovi/' + this.id,
            active_route: '',
            allBreadcrumbs: []
        }
    },
    methods:{
        open( name ){
            if( name !== this.active_route ){
                this.active_route = name
                this.$router.push( name )
            }
        },
        getKupac(){
            let vm = this
            axios.get( process.env.VUE_APP_API_URL + 'skup' + '/' + vm.id )
                .then( function ( response ) {
                    vm.skup = response.data
                    vm.setBreadcrumbs()
                } )
                .catch( function ( error ) {
                    if(error instanceof Error) {
                        console.log( 'Error: ' . error);
                    } else {
                        console.log( 'Unexpected response: ' . error);
                    }
                } )
        },
        setBreadcrumbs(){
            let vm = this

            if( vm.skup ){
                vm.allBreadcrumbs = [
                    {
                        path: '/skupovi',
                        name: 'Skup'
                    },
                    {
                        path: ( '/skupovi/' + this.id ),
                        name: ( 'Skup: ' + this.skup.broj_skupa + ' - ' + this.skup.naziv  )
                    },
                ]

                vm.breadcrumbs.forEach( function( b ){
                    vm.allBreadcrumbs.push( b )
                } )
            }
        }
    },
    mounted() {
        this.active_route = this.$route.path
        this.getKupac()
    },
    watch:{
        breadcrumbs: {
            deep: true,
            handler: function(){
                this.setBreadcrumbs()
            }
        },
    },
};
</script>

<style scoped>

</style>
