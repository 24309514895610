<template>
    <div>
        <breadcrumbs
            :title="'Vrste suorganizatora'"
            :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Vrste suorganizatora</h5>
                <br>
                <button
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click.prevent="create"
                >
                    Nova vrsta suorganizatora
                </button>
            </div>

        </div>
        <div class="ibox-content">
            <datatables
                v-if="show_datatable"
                :url="datatables_url"
                :settings="datatables.settings"
                :title="datatables.title"
                @rowClick="rowClick"
            ></datatables>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import Datatables from "../../../../components/datatable/Datatables";
export default {
    name: "VrsteSuorganizatora",
    components: { Datatables, Breadcrumbs },
    data(){
        return {
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL + 'vrsta-suorganizatora',
            datatables: {},
            breadcrumbs: []
        }
    },
    methods: {
        rowClick( row ){
            this.$router.push( { path: '/postavke/vrste-suorganizatora/' + row.id } )
        },

        create(){
            this.$router.push( { path: '/postavke/vrste-suorganizatora/create' } )
        },

        setDatatable(){
            let vm = this

            vm.datatables = {
                settings: {
                    table_header: [
                        {
                            'id': 'id',
                            'visible': false
                        },
                        {
                            'id': 'naziv',
                            'title': 'Naziv',
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'vrsta-suorganizatora',
                                'visible' : true
                            }
                        }
                    ],
                    column_visibility_groups:{
                        'vrsta-suorganizatora' : 'Vrste suorganizatora'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Vrste suorganizatora'
            }
            vm.show_datatable = true;
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/postavke/vrste-suorganizatora',
                    name: 'Vrste suorganizatora'
                }
            ]
        }
    },
    mounted() {
        this.setDatatable()
        this.setBreadcrumbs()
    }

};
</script>

<style scoped>

</style>
