import { render, staticRenderFns } from "./VrstaPrometaEdit.vue?vue&type=template&id=6c3e4832&scoped=true&"
import script from "./VrstaPrometaEdit.vue?vue&type=script&lang=js&"
export * from "./VrstaPrometaEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c3e4832",
  null
  
)

export default component.exports