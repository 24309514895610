<template>
    <div>
        <skupovi-navigation
            :id="skup_id"
            :breadcrumbs="breadcrumbs"
        >
        </skupovi-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Nova stavka</h5>
            </div>
            <div class="ibox-content">
                <skup-stavka-forma
                    :disabled="false"
                    :id="null"
                    :modal="false"
                    :action="'create'"
                >
                </skup-stavka-forma>
            </div>
        </div>
    </div>
</template>

<script>
import SkupoviNavigation from "../SkupNavigation";
import SkupStavkaForma from "./SkupStavkaForma";
export default {
    name: "SkupStavkaCreate",
    components: { SkupStavkaForma, SkupoviNavigation },
    data(){
        return {
            breadcrumbs: [],
            skup_id: this.$route.params.skup_id
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/skupovi/' + this.skup_id + '/stavke',
                    name: 'Stavke'
                },
                {
                    path: '/skupovi/' + this.skup_id + '/stavke',
                    name: 'Nova stavka'
                }
            ]
        }
    },
    mounted() {
        this.setBreadcrumbs()
    }
};
</script>

<style scoped>

</style>
