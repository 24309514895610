import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import toastr from "toastr";

Vue.config.productionTip = false;


axios.interceptors.response.use(
  response => response,
  error => {
    // 422 su validacijske greške i ne hvatam ih više ovdje
    // if (error.response.status === 422) {
    //   store.commit("setErrors", error.response.data.errors);
    // } else
    if (error.response.status === 401) {
      /**
       * todo:: trebalo bi poslati refresh token ako je prijava istekla i obnoviti je
       */
      store.commit("auth/setUserData", null);
      localStorage.removeItem("access_token");
      router.push( '/login');
    } else {
      return Promise.reject(error);
    }
  }
);

axios.interceptors.request.use(function(config) {
  config.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  return config;
});


toastr.options.closeButton = true ;
toastr.options.progressBar = false;
toastr.options.showMethod = 'slideDown';
toastr.options.timeOut = 4000;
// toastr.error( 'test' )
// toastr.warning( 'test' )
// toastr.success( 'test' )

window._ = require('lodash');

window.moment = require('moment')

window.XLSX = require('xlsx');
window.DOCX = require('docx')


/*
token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}*/


/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: 'your-pusher-key',
//     cluster: 'mt1',
//     encrypted: true
// });


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

import VueHead from 'vue-head'
Vue.use( VueHead )