<template>
    <div>
        <forms
            :settings="settings"
            @onFormButtonClick="onFormButtonClick"
            @onInput="onInput"
        >
        </forms>
    </div>
</template>

<script>
import Forms from "../../../../components/form/Forms";
import axios from "axios";
import toastr from "toastr";
export default {
    name: "OsobaDodatnaAktivnostForma",
    components: { Forms },
    props:{
        disabled:{
            type: Boolean,
            default: false
        },
        id:{
            default: null
        },
        modal:{
            type: Boolean,
            default: false
        },
        action: {
            type: String
        }
    },
    data(){
        return {
            settings: {},
            errors: {},
            route: '/osobe/' + this.$route.params.osoba_id + '/dodatne-aktivnosti',
            url: 'osoba-dodatna-aktivnost',
            data: {},
            osoba_id: this.$route.params.osoba_id,

            dodatne_aktivnosti_select: [],
            podrucja_tema_select: []
        }
    },

    methods: {

        async fetctDodatneAktivnosti(){
            let vm = this
            const response = await axios.get(process.env.VUE_APP_API_URL + 'dodatna-aktivnost' )
            return response.data.data.map( function( item ) {
                vm.dodatne_aktivnosti_select.push(
                    {
                        key: item.id.toString(),
                        value: item.naziv
                    }
                )
            } )
        },

        getDodatnaAktivnost(id) {
            return this.dodatne_aktivnosti_select.find( item => item.key === id )
        },

        async fetctPodrucjaTema(){
            let vm = this
            const response = await axios.get(process.env.VUE_APP_API_URL + 'podrucje-teme' )
            return response.data.data.map( function( item ) {
                vm.podrucja_tema_select.push(
                    {
                        key: item.id.toString(),
                        value: item.naziv
                    }
                )
            } )
        },

        getPodrucjeTeme(id) {
            return this.podrucja_tema_select.find( item => item.key === id )
        },

        async init(){

            let vm = this

            await vm.fetctDodatneAktivnosti()
            await vm.fetctPodrucjaTema()

            if ( vm.action === 'edit' || vm.action === 'show' ){

                axios.get( process.env.VUE_APP_API_URL + vm.url + '/' + vm.id )
                    .then( function ( response ) {

                        vm.data['osoba_id'] = response.data.osoba_id.toString()
                        vm.data['dodatna_aktivnost_id'] = response.data.dodatna_aktivnost_id ? response.data.dodatna_aktivnost_id.toString() : ''
                        vm.data['podrucje_teme_id'] = response.data.podrucje_teme_id ? response.data.podrucje_teme_id.toString() : ''
                        vm.data['datum_od'] = response.data.datum_od
                        vm.data['datum_do'] = response.data.datum_do
                        vm.data['opis'] = response.data.opis
                        vm.data['sati'] = response.data.sati.toString()
                        vm.data['sati_regulativa'] = response.data.sati_regulativa.toString()

                        vm.$emit( 'returnData', response.data )
                        vm.setupForm()
                    } )
                    .catch( function ( error ) {
                        if(error instanceof Error) {
                            console.log( 'Error: ' . error);
                        } else {
                            console.log( 'Unexpected response: ' . error);
                        }
                    } )

            } else if ( vm.action === 'create' ) {
                vm.data = {}
                vm.setupForm()
            } else {
                return
            }
        },

        setupForm(){

            let vm = this;

            vm.buttons = []

            let button_cancel = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
                action: 'redirect', // request
                config: {
                    url: vm.route + ( vm.action === 'edit' ? ( '/' + vm.id) : '' )
                }
            }

            let button_delete = {
                id: 'delete',
                title: 'Obriši',
                type: 'danger',
                action: 'request',
                config: {
                    method: 'DELETE',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let button_edit = {
                id: 'edit',
                title: 'Uredi',
                type: 'success',
                action: 'redirect',
                config: {
                    url: vm.route +  '/' + vm.id +'/edit'
                }
            }

            let button_save = {
                id: 'save',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + vm.url
                }
            }

            let button_update = {
                id: 'update',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'PUT',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let buttons = []


            if( vm.action === 'edit' ){
                buttons.push( button_cancel)
                buttons.push( button_update)
            } else if( vm.action === 'show' ) {
                buttons.push( button_cancel )
                buttons.push( button_delete )
                buttons.push( button_edit )
            }
            else if ( vm.action === 'create' ) {
                buttons.push( button_cancel )
                buttons.push( button_save )
            } else {
                return
            }

            let inputs = []


            let osoba_id_input = {
                name: 'osoba_id',
                type: 'hidden',
                value: vm.osoba_id,
                disabled: vm.disabled,
                config: {
                    hidden:true
                }
            }
            inputs.push( osoba_id_input )

            let dodatna_aktivnost_id_input = {
                name: 'dodatna_aktivnost_id',
                type: 'select',
                title: 'Dodatna aktivnost',
                value: vm.data.dodatna_aktivnost_id ? vm.getDodatnaAktivnost( vm.data.dodatna_aktivnost_id ) : '',
                width: 12,
                error: vm.errors['dodatna_aktivnost_id'] ? vm.errors['dodatna_aktivnost_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.dodatne_aktivnosti_select,
                }
            }
            inputs.push( dodatna_aktivnost_id_input )

            let podrucje_teme_id_input = {
                name: 'podrucje_teme_id',
                type: 'select',
                title: 'Područje teme',
                value: vm.data.podrucje_teme_id ? vm.getPodrucjeTeme( vm.data.podrucje_teme_id ) : '',
                width: 12,
                error: vm.errors['podrucje_teme_id'] ? vm.errors['podrucje_teme_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.podrucja_tema_select,
                }
            }
            inputs.push( podrucje_teme_id_input )

            let datum_od_input = {
                name: 'datum_od',
                type: 'datepicker',
                title: 'Datum od',
                value: vm.data.datum_od,
                width: 12,
                error: vm.errors['datum_od'] ? vm.errors['datum_od'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
            }
            inputs.push( datum_od_input )

            let datum_do_input = {
                name: 'datum_do',
                type: 'datepicker',
                title: 'Datum do',
                value: vm.data.datum_do,
                width: 12,
                error: vm.errors['datum_do'] ? vm.errors['datum_do'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,

            }
            inputs.push( datum_do_input )

            let opis_input = {
                name: 'opis',
                type: 'input',
                title: 'Opis',
                value:  vm.data.opis,
                width: 12,
                error: vm.errors['opis'] ? vm.errors['opis'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
            }
            inputs.push( opis_input )

            let sati_input = {
                name: 'sati',
                type: 'input',
                title: 'Sati',
                value:  vm.data.sati,
                width: 12,
                error: vm.errors['sati'] ? vm.errors['sati'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
            }
            inputs.push( sati_input )

            let sati_regulativa_input = {
                name: 'sati_regulativa',
                type: 'input',
                title: 'Sati regulativa',
                value:  vm.data.sati_regulativa,
                width: 12,
                error: vm.errors['sati_regulativa'] ? vm.errors['sati_regulativa'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
            }
            inputs.push( sati_regulativa_input )


            let main_section = {
                name: ' ',
                width: 12,
                inputs: inputs
            }

            let sections = []

            sections.push( main_section )


            let settings = {
                width: 6,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: sections
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },

        /**
         *  on input return data
         */
        onInput( name, value ){
            if( this.data[name] !== value ){
                this.data[name] = value
            }
        },

        /**
         *  on button click
         */
        onFormButtonClick( id, action, config, data  ){

            if( id === 'save' || id === 'update' ){
                if( data.success){
                    if( id == 'save' )
                        toastr.success( 'Uspješno spremljeno!' )
                    else if ( id == 'update' )
                        toastr.success( 'Uspješno ažurirano!' )
                    this.$router.push({ path: this.route + '/' + data.data.id } )
                } else {
                    this.errors = data.messages
                    this.setupForm()
                }
            } else if( id == 'delete' ){
                toastr.error( 'Uspješno obrisano!' )
                this.$router.push({ path: this.route } )
            }

        },
    },

    mounted() {
        this.init()
    }
}
</script>

<style scoped>

</style>
