<template>
    <div>
        <breadcrumbs
            :title="'Dodatne aktivnosti'"
            :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Dodatna aktivnost: {{ naziv }}</h5>
            </div>
        </div>
        <div class="ibox-content">
            <dodatna-aktivnost-forma
                :disable="false"
                :id="id"
                :modal="false"
                :action="'edit'"
                @returnData="setData"
            ></dodatna-aktivnost-forma>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import DodatnaAktivnostForma from "./DodatnaAktivnostForma";
export default {
    name: "DodatnaAktivnostEdit",
    components: { DodatnaAktivnostForma, Breadcrumbs },
    data(){
        return {
            id: this.$route.params.dodatna_aktivnost_id,
            breadcrumbs: [],
            naziv: ''
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/postavke/dodatne-aktivnosti',
                    name: 'Dodatne aktivnosti'
                },
                {
                    path: '/postavke/dodatne-aktivnosti/' + this.id,
                    name: 'Dodatna aktivnost' + this.naziv
                },
                {
                    path: '/postavke/dodatne-aktivnosti/' + this.id + '/edit',
                    name: 'Uredi dodatnu aktivnost: ' + this.naziv
                }
            ]
        },
        setData( data ){
            this.naziv = data.naziv || ''
            this.setBreadcrumbs()
        }
    }
};
</script>

<style scoped>

</style>