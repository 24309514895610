<template>
    <div>
        <osobe-navigation
            :id="id"
            :breadcrumbs="breadcrumbs"
        >
        </osobe-navigation>


        <div v-show="show_skup" class="ibox ">
            <div class="ibox-title">
                <h5>Osoba: {{ naziv }} </h5>
            </div>
            <div class="ibox-content">
                <osoba-forma
                    :disabled="true"
                    :id="id"
                    :modal="false"
                    :action="'show'"
                    @returnData="setData"
                >
                </osoba-forma>

                <br><br>
                <h2><strong>Grupa statusa: {{ status }}</strong></h2>
                <br><br>
                <h2><strong>Razdoblja:</strong></h2>
                <table class="table">
                    <thead>
                    <tr>
                        <th>Početak razdoblja</th>
                        <th>Završetak razdoblja</th>
                        <th>Potrebno skupiti sati</th>
                        <th>Potrebno skupiti sati regulative</th>
                        <th>Skupljeni sati</th>
                        <th>Skupljeni sati regulative</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="razdoblje in razdoblja" :key="razdoblje.id">
                        <td>{{razdoblje.datum_od}}</td>
                        <td>{{ razdoblje.datum_do }}</td>
                        <td>{{ razdoblje.sati }}</td>
                        <td>{{ razdoblje.sati_regulativa }}</td>
                        <td>{{ razdoblje.skupljeno_sati }}</td>
                        <td>{{ razdoblje.skupljeno_sati_regulativa }}</td>
                    </tr>
                    </tbody>
                </table>
                <br><br><br><br>
            </div>
        </div>
    </div>
</template>

<script>
import OsobeNavigation from "./OsobeNavigation";
import OsobaForma from "./OsobaForma";
import axios from "axios";
export default {
    name: "OsobaDetails",
    components: { OsobaForma, OsobeNavigation },
    props: ['user'],
    data(){
        return {
            id: this.$route.params.osoba_id,
            breadcrumbs: [],
            naziv: '',
            show_skup: true,

            status: '',
            razdoblja:[]
        }
    },
    methods:{

        async fetchRazdoblja(){
            let vm = this
            const response = await axios.get(process.env.VUE_APP_API_URL + 'osoba-razdoblje', {
                params: {
                    filters: {
                        osoba_id: vm.id
                    }
                }
            } )

            for( const razdoblje in response.data.data ){

                const stavke = await vm.fetchStavke( response.data.data[razdoblje].datum_od, response.data.data[razdoblje].datum_do )
                const dodatne_aktivnosti = await vm.fetchDodatneAktivnosti( response.data.data[razdoblje].datum_od, response.data.data[razdoblje].datum_do )
                let sati = 0
                let sati_regulativa = 0

                console.log( dodatne_aktivnosti )

                for( const stavka in stavke ){
                    sati += stavke[stavka].sati
                    sati_regulativa += stavke[stavka].sati_regulativa
                }

                for( const dodatna_aktivnost in dodatne_aktivnosti ){
                    sati += dodatne_aktivnosti[dodatna_aktivnost].sati
                    sati_regulativa += dodatne_aktivnosti[dodatna_aktivnost].sati_regulativa
                }

                response.data.data[razdoblje]['skupljeno_sati'] = sati
                response.data.data[razdoblje]['skupljeno_sati_regulativa'] = sati_regulativa
            }

            vm.razdoblja = response.data.data
        },

        async fetchStavke( datum_od, datum_do ){
            let vm = this
            const response = await axios.get(process.env.VUE_APP_API_URL + 'stavka', {
                params: {
                    filters: {
                        osoba_id: vm.id,
                        skup_datum_i_vrijeme_od: {
                            from: datum_od,
                            to:datum_do,
                        },
                        page:1,
                        per_page: -1
                    }
                }
            } )

            return response.data.data;
        },

        async fetchDodatneAktivnosti( datum_od, datum_do ){
            let vm = this
            const response = await axios.get(process.env.VUE_APP_API_URL + 'osoba-dodatna-aktivnost', {
                params: {
                    filters: {
                        osoba_id: vm.id,
                        datum_od: {
                            from: datum_od,
                            to:datum_do,
                        },
                        page:1,
                        per_page: -1
                    }
                }
            } )

            return response.data.data;
        },

        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/osobe',
                    name: 'Osobe'
                },
                {
                    path: ( '/osobe/' + this.id ),
                    name: ( 'Osobe: ' + this.naziv )
                }
            ]
        },

        async setData( data ){
            this.naziv = data.ime + ' ' + data.prezime + ' (' + data.oib + ')'
            this.status = data.osoba_grupe_statusa[0] && data.osoba_grupe_statusa[0].grupa_statusa ? data.osoba_grupe_statusa[0].grupa_statusa.naziv : ''
            this.setBreadcrumbs()
            await this.fetchRazdoblja()
        }
    },

};
</script>

<style scoped>

</style>
